import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert.component";
import {
  GeneralDataApiAxios,
  TeachersApiAxiosPrivate,
} from "../../config/axios";
import { PreferencesContext } from "../../context/PreferencesContext";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";

function CreateCourse() {
  const { language: lang } = useContext(PreferencesContext);
  const [stages, setStages] = useState<any[]>([]);

  const [courseName, setCourseName] = useState<string>("");
  const [slug, setSlug] = useState<string>("");
  const [stage, setStage] = useState<string>("");
  const [cover, setCover] = useState<any>();
  const [description, setDescription] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const askApi = async () => {
      const stagesResponse = await GeneralDataApiAxios.get(`/stages`);
      setStages(stagesResponse.data.data);
    };

    askApi();
  }, []);

  const handleBlur = async () => {
    setLoading(true);
    try {
      if (courseName.length > 0 && slug.length === 0) {
        const { data } = await TeachersApiAxiosPrivate.get(
          `/course/getslug/${courseName}`
        );
        setSlug(data.data.slug);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", courseName);
      formData.append("description", description);
      formData.append("slug", slug);
      formData.append("cover", cover);
      formData.append("stage", stage);
      await TeachersApiAxiosPrivate.post("/course", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      navigate(`/course/${slug}`);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("lecture.courses.create.title")}</h1>
          <p>{t("lecture.courses.create.description")}</p>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <section className={styles.formSection}>
          {error && (
            <div className={styles.alerts}>
              <Alert type="danger" content={error} />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="course_name">{t("course_name")}</label>
            <input
              onChange={(e: any) => setCourseName(e.target.value)}
              onBlur={handleBlur}
              value={courseName}
              id="course_name"
              type="text"
              placeholder={t("course_name")!}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="slug">{t("slug")}</label>
            <input
              onChange={(e: any) => setSlug(e.target.value)}
              value={slug}
              id="slug"
              type="text"
              placeholder={t("slug")!}
              disabled={isLoading}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="stage">{t("stage")}</label>
            <select onChange={(e) => setStage(e.target.value)}>
              {stages &&
                stages.map((stage: any, idx: number) => (
                  <option key={idx} value={stage.id}>
                    {lang === "en" ? stage.name_en : stage.name_ar}
                  </option>
                ))}
            </select>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="cover">{t("cover")}</label>
            <input
              onChange={(e: any) => setCover(e.target.files[0])}
              id="cover"
              type="file"
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="description">{t("description")}</label>
            <textarea
              onChange={(e: any) => setDescription(e.target.value)}
              value={description}
              id="description"
              placeholder={t("description")!}
            ></textarea>
          </div>

          <button className="btn btn-primary">
            {t("lecture.courses.create.title")}
          </button>
        </section>
      </form>
    </DashboardLayout>
  );
}

export default CreateCourse;
