import React, { useContext } from "react";
import {
  MdDashboard,
  MdPayment,
  FaCog,
  BiBookAlt,
  MdOutlineLocalAtm,
} from "../../config/icons";
import { Link, useLocation } from "react-router-dom";

import styles from "../../styles/layouts/components/dashboardsidebar.module.scss";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

function DashboardSidebar() {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();

  const links: any[][] = [
    [<MdDashboard />, t('My Dashboard'), "/dashboard"],
    [<MdPayment />, t('payment.title'), "/dashboard/payment"],
    [<FaCog />, t('Settings'), "/dashboard/settings"],
  ];
  
  const teacherDashboardLinks: any[][] = [
    [<BiBookAlt />, t('showcase.courses.link'), "/dashboard/teacher/courses"],
    [<MdOutlineLocalAtm />, t('withdraw.title'), "/dashboard/withdraw"],
  ];

  return (
    <div className={styles.sidebar}>
      <h2>{t('MyEdutime Dashboard')}</h2>
      <hr />
      <ul>
        {links.map((link, idx) => (
          <Link
            className={pathname === link[2] ? styles.openedLink : ""}
            to={link[2]}
            key={idx}
          >
            <li>
              {link[0]}
              <span>{link[1]}</span>
            </li>
          </Link>
        ))}
      </ul>
      {user && (user.role === "teacher" || user.role === "admin") && (
        <>
          <h3 className={styles.seperatorTitle}>{t('Teacher Dashboard')}</h3>
          <hr />
          <ul>
            {teacherDashboardLinks.map((link, idx) => (
              <Link
                className={pathname === link[2] ? styles.openedLink : ""}
                to={link[2]}
                key={idx}
              >
                <li>
                  {link[0]}
                  <span>{link[1]}</span>
                </li>
              </Link>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}

export default DashboardSidebar;
