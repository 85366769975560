import { useTranslation } from "react-i18next";
import { FaVideo, HiOutlineExternalLink } from '../config/icons';

import styles from '../styles/components/lectureContent.module.scss';

interface Props {
  video: any
}

function Video({ video }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.element}>
      <div className={styles.mainSection}>
        <span className={styles.icon}>
          <FaVideo />
        </span>
        <div className={styles.text}>
          <h3>{video.name}</h3>
          <p>
            {video.watches.remaining_times} {t("out_of")}{" "}
            {video.watches.max_views} {t("watches_left")}
          </p>
        </div>
      </div>
      <span className={styles.icon}>
        <HiOutlineExternalLink />
      </span>
    </div>
  );
}

export default Video;
