import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GeneralApiAxiosPrivate } from "../../../config/axios";
import MainLayout from "../../../layouts/MainLayout.layout";

import styles from "../../../styles/pages/Exam.module.scss";

function Exam() {
  const [requiredToPass, setRequiredToPass] = useState(true);
  const [code, setCode] = useState();
  const [endTime, setEndTime] = useState();
  const [questionCount, setQuestionCount] = useState();
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.post(`/exam/${slug}`, {
          'user_agent': window.navigator.userAgent
        });
        const { code, end_time, exam, question_count } = data.data;
  
        setCode(code);
        setCode(code);
        setEndTime(end_time);
        setQuestionCount(question_count);
        setRequiredToPass(exam.pass_required);
      } catch (e: any) {
        console.error(e);

        if (e.response.status = 403) {
          navigate(`/exam/${slug}/results`)
        }
      }
    }

    askApi();
  }, [])

  return (
    <MainLayout>
      <div className={styles.wrapper}>
        <div className={styles.examContainer}>
          <table>
            <thead>
              <tr>
                <td>Title</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Questions Number</td>
                <td>{questionCount}</td>
              </tr>
              <tr>
                <td>Exam Slug</td>
                <td>{slug}</td>
              </tr>
              <tr>
                <td>Pass Required</td>
                <td>{+requiredToPass === 1 ? 'Yes' : 'No'}</td>
              </tr>
              <tr>
                <td>Exam End Time</td>
                <td>{endTime}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Link className="btn btn-primary" to={`/exam/${code}/start`}>
          Start Exam
        </Link>
      </div>
    </MainLayout>
  );
}

export default Exam;
