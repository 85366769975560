import React from 'react'
import LoadingIcon from '../static/img/1481.png'

import styles from '../styles/components/loading.module.scss'

interface Props {
  children?: any
}

function Loading({ children = [] }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.overlay}>
        <img src={LoadingIcon} alt="Loading Icon" />
      </div>
      {children}
    </div>
  )
}

export default Loading