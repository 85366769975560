import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Alert from "../components/Alert.component";
import { GENERAL_API } from "../config/api";
import { GeneralApiAxiosPrivate, StudentApiAxiosPrivate, TeachersApiAxiosPrivate } from "../config/axios";
import { PreferencesContext } from "../context/PreferencesContext";

import useAuth from '../hooks/useAuth';
import Logo from "../static/img/korasti.png";
import LogoAr from "../static/img/korasti_ar.png";
import LoginPicture from "../static/svg/login-picture.svg";

import styles from "../styles/pages/login.module.scss";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string|null>(null);
  const { setLoggedIn, setToken } = useAuth();
  const { language } = useContext(PreferencesContext);
  
  const naviagte = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { t } = useTranslation();

  const FormSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const { data } = await axios.post(`${GENERAL_API}/auth/login`, {
        email,
        password
      });

      const { token, user } = data.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      GeneralApiAxiosPrivate.defaults.headers['Authorization'] = `Bearer ${token}`;
      StudentApiAxiosPrivate.defaults.headers['Authorization'] = `Bearer ${token}`;
      TeachersApiAxiosPrivate.defaults.headers['Authorization'] = `Bearer ${token}`;


      setLoggedIn(true);
      setToken(token);
      naviagte(from, {replace: true});
    } catch (e: any) {
      setError(e.response.data.message);
      setPassword('');
      console.error(e);
    }
  }

  return (
    <div className={styles.loginPageBody}>
      <section className={styles.leftSide}>
        <Link className={styles.logoLink} to="/">
          <div className={styles.logo}>
            {language === "en" ? <img src={Logo} alt="logo" /> : <img src={LogoAr} alt="logo" />}
          </div>
        </Link>
        <form onSubmit={FormSubmit}>
          <h1 className={styles.title}>{t("login.title")}</h1>
          <p className={styles.description}>{t("login.description")}</p>
          <div className={styles.alerts}>
            {error && (
              <Alert type="danger" content={error} />
            )}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">{t("email")}</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" placeholder="ahmed@korasti.com" />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">{t("password")}</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              placeholder="Enter your password"
            />
          </div>
          <Link to="/register">{t("Don't Have an Account?")}</Link>
          <button type="submit">{t("login.title")}</button>
        </form>
      </section>
      <section className={styles.rightSide}>
        <img src={LoginPicture} alt="Login Picture" />
      </section>
    </div>
  );
}

export default Login;
