import React from "react";

import styles from '../styles/components/coursesPageContainer.module.scss';

interface Props {
  children: any;
}

function CoursesPageCotainer({ children }: Props) {
  return <div className={styles.coursesContainer}>{children}</div>;
}

export default CoursesPageCotainer;
