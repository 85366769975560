import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/Teacher/dashboard.module.scss";

function LectureContent() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [videos, setVideos] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [exams, setExams] = useState<any[]>([]);
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      const { data } = await TeachersApiAxiosPrivate.get(`/lecture/${id}`);

      const { videos, attachments, exams } = data.data;

      setVideos(videos);
      setAttachments(attachments);
      setExams(exams);
      setLoading(false);
    };

    askApi();
  }, [setVideos, setAttachments, setExams, setLoading]);

  return (
    <DashboardLayout>
      {!isLoading ? (
        <>
          <section className={styles.dashboardSection}>
            <nav className={styles.dashboardSectionNavigation}>
              <h1 className={styles.sectionTitle}>{t('Videos')}</h1>
              <Link
                className="btn btn-primary"
                to={`/dashboard/teacher/lectures/${id}/videos/create`}
              >
                {t('create')}
              </Link>
            </nav>
            <hr />
            <div className={styles.dashboardTableWrapper}>
              <table className={styles.dashboardTable}>
                <thead>
                  <tr>
                    <td>{t('name')}</td>
                    <td>{t('max_views')}</td>
                    <td>{t('slug')}</td>
                  </tr>
                </thead>
                <tbody>
                  {videos && videos.map((video, idx) => (
                    <tr key={idx}>
                      <td>{video.name}</td>
                      <td>{video.max_views}</td>
                      <td>{video.slug}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <section className={styles.dashboardSection}>
            <nav className={styles.dashboardSectionNavigation}>
              <h1 className={styles.sectionTitle}>{t('Attachments')}</h1>
              <Link
                className="btn btn-primary"
                to={`/dashboard/teacher/lectures/${id}/attachments/create`}
              >
                {t('create')}
              </Link>
            </nav>
            <hr />
            <div className={styles.dashboardTableWrapper}>
              <table className={styles.dashboardTable}>
                <thead>
                  <tr>
                    <td>{t('name')}</td>
                    <td>{t('type')}</td>
                    <td>{t('size')}</td>
                    <td>{t('download')}</td>
                  </tr>
                </thead>
                <tbody>
                  {attachments && attachments.map((attachment, idx) => (
                    <tr key={idx}>
                      <td>{attachment.name}</td>
                      <td>{attachment.type}</td>
                      <td>{attachment.size}</td>
                      <td><a href={attachment.link}>Download</a></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
          <section className={styles.dashboardSection}>
            <nav className={styles.dashboardSectionNavigation}>
              <h1 className={styles.sectionTitle}>{t('Exams')}</h1>
              <Link
                className="btn btn-primary"
                to={`/dashboard/teacher/lectures/${id}/exams/create`}
              >
                {t('create')}
              </Link>
            </nav>
            <hr />
            <div className={styles.dashboardTableWrapper}>
              <table className={styles.dashboardTable}>
                <thead>
                  <tr>
                    <td>{t('time')}</td>
                    <td>{t('questions_count')}</td>
                    <td>{t('pass_required')}</td>
                    <td>{t('slug')}</td>
                  </tr>
                </thead>
                <tbody>
                  {exams && exams.map((exam, idx) => (
                    <tr key={idx}>
                      <td>{exam.time / 60} {t('minutes')}</td>
                      <td>{exam.questions_count}</td>
                      <td>{exam.pass_required === true ? 'Yes' : 'No'}</td>
                      <td>{exam.slug}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </>
      ) : (
        <div className="loading-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </DashboardLayout>
  );
}

export default LectureContent;
