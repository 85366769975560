import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";

function AddAttachmentToLecture() {
  const [isLoading, setLoading] = useState(false);
  const [attachmentName, setAttachmentName] = useState<string>("");
  const [attachment, setAttachment] = useState<any>("");
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  const { id } = useParams();
  const lectureID: any = id;
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", attachmentName);
      formData.append("file", attachment);
      formData.append("lecture_id", lectureID);

      await TeachersApiAxiosPrivate.post("/lecture/attachments", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      navigate(`/dashboard/teacher/lectures/${lectureID}`);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
    }
    setLoading(false);
  };

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("lecture.attachments.create.title")}</h1>
          <p>{t("lecture.attachments.create.description")}</p>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <section className={styles.formSection}>
          {error && (
            <div className={styles.alerts}>
              <Alert type="danger" content={error} />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="attchment_name">{t("attchment_name")}</label>
            <input
              onChange={(e) => setAttachmentName(e.target.value)}
              id="attchment_name"
              type="text"
              placeholder={t("attchment_name")!}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="attachment">{t("attachment")}</label>
            <input
              onChange={(e: any) => setAttachment(e.target.files[0])}
              id="attachment"
              type="file"
            />
          </div>

          <button className="btn btn-primary">
            {t("lecture.attachments.create.title")}
          </button>
        </section>
      </form>
    </DashboardLayout>
  );
}

export default AddAttachmentToLecture;
