import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";

import MainLayout from "../../../layouts/MainLayout.layout";
import { BiAlarmExclamation } from "../../../config/icons";

import styles from "../../../styles/pages/startExam.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralApiAxiosPrivate } from "../../../config/axios";
import LoadingSpinner from "../../../components/LoadingSpinner.component";

function ExamResults() {
  const [isLoading, setLoading] = useState(true);
  const [lectureName, setLectureName] = useState('');
  const [lectureSlug, setLectureSlug] = useState('');
  const [examStatus, setExamStatus] = useState("passed");
  const [grade, setGrade] = useState("0");
  const [questions, setQuestions] = useState([]);
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.get(
          `/exam/${code}/results`
        );
        const { results, status, answers, lecture_name, lecture_slug } = data.data;

        setExamStatus(status);
        setGrade(results);
        setQuestions(answers);
        setLoading(false);
        setLectureName(lecture_name);
        setLectureSlug(lecture_slug);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [code]);

  const backToLecture = () => navigate(`/course/${lectureSlug}`);

  return (
    <MainLayout>
      {!isLoading ? (
        <>
          <nav className={styles.examNavigationBar}>
            <h1>{lectureName}</h1>
            <span className={styles.grade}>
              {t("grade")}: {grade}
            </span>
            <span
              className={`${styles.examStatus} ${
                examStatus === "passed" ? styles.passed : styles.failed
              }`}
            >
              {examStatus}
            </span>
          </nav>
          <section className={styles.examQuestions}>
            {questions &&
              questions.map((question: any, idx: number) => (
                <div key={idx} className={styles.questionCard}>
                  <div className={styles.question}>
                    <span>{++idx}.</span>
                    <span>{question.question}</span>
                  </div>
                  <div className={styles.answers}>
                    {Object.values(question.answers).map((answer: any, idx: number) => (
                      <div
                      key={idx}
                        className={`${styles.unchoosableAnswer} ${answer === question.correct_answer ? styles.chosenAnswer : ''}`}
                        data-type="answer"
                      >
                        {answer}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            <button onClick={backToLecture} className="btn btn-primary">Back To Lecture</button>
          </section>
        </>
      ) : (
        <div className="page-loading-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </MainLayout>
  );
}

export default ExamResults;
