import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GeneralDataApiAxios } from '../config/axios';

import styles from '../styles/components/coursesFilter.module.scss';

const lang = localStorage.getItem('lang') || 'en';

interface Props {
  setSearch: any;
  setSubject: any;
  setStage: any;
}

function CoursesFilter({ setSearch, setSubject, setStage }: Props) {
  const [subjects, setSubjects] = useState<any[]>([]);
  const [stages, setStages] = useState<any[]>([]);
  
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const subjects: any = await GeneralDataApiAxios.get('/subjects');
        const stages: any = await GeneralDataApiAxios.get('/stages');
        setSubjects(subjects.data.data);
        setStages(stages.data.data);
      } catch (e) {
        console.error(e);
      }
    }

    askApi();
  }, []);

  return (
    <div className={styles.coursesFilter}>
      <h2>{t('search_filters')}</h2>
      <input onChange={(e) => setSearch(e.target.value)} type="text" placeholder={t('searchbox.placeholder')!} />
      <section className={styles.filterBox}>
        <select onChange={(e) => setStage(e.target.value)}>
          <option value="">{t('stage_option')}</option>
          {stages && stages.map((stage, idx) => (
            <option key={idx} value={stage.id}>{lang == 'en' ? stage.name_en : stage.name_ar}</option>
          ))}
        </select>
        <select onChange={(e) => setSubject(e.target.value)}>
          <option value="">{t('subject_option')}</option>
          {subjects && subjects.map((subject, idx) => (
            <option key={idx} value={subject.id}>{lang == 'en' ? subject.name_en : subject.name_ar}</option>
          ))}
        </select>
      </section>
    </div>
  )
}

export default CoursesFilter