import React from 'react'

import styles from '../styles/components/dashboardCard.module.scss';

interface Props {
  cardKey: string;
  cardValue: string;
}

function DashboardCard({ cardKey, cardValue }: Props) {
  return (
    <div className={styles.card}>
      <h1 className={styles.key}>{cardKey}</h1>
      <span className={styles.value}>{cardValue}</span>
    </div>
  )
}

export default DashboardCard