import { useTranslation } from 'react-i18next';
import DashboardCard from '../../components/DashboardCard.component';
import useAuth from '../../hooks/useAuth';
import DashboardLayout from '../../layouts/DashboardLayout.layout'

import styles from '../../styles/pages/dashboard.module.scss';

function Dashboard() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <DashboardLayout>
      {user && (
        <div className={styles.dashboardCards}>
          <DashboardCard cardKey={t('name')} cardValue={user.name} />
          {user.role === 'student' && (
            <>
              <DashboardCard cardKey={t('goverment')} cardValue={user.goverment ?? 'N/A'} />  
              <DashboardCard cardKey={t('stage')} cardValue={user.stage ?? 'N/A'} />
              <DashboardCard cardKey={t('school_name')} cardValue={user.school_name ?? 'N/A'} />
            </>
          )}
          <DashboardCard cardKey={t('Balance')} cardValue={`${user.balance} ${t('EGP')}`} />
          <DashboardCard cardKey={t('email')} cardValue={user.email} />
        </div>
      )}
    </DashboardLayout>
  )
}

export default Dashboard