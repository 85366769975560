import { ReactComponent as DangerIcon } from '../static/svg/dangerIcon.svg';
import { ReactComponent as WarningIcon } from '../static/svg/warningIcon.svg';
import { ReactComponent as InfoIcon } from '../static/svg/infoIcon.svg';
import { ReactComponent as SuccessIcon } from '../static/svg/successIcon.svg';

interface Props {
  type: string;
  content: string;
}

function Alert({ type, content }: Props) {
  return (
    <div className={`alert ${type}`}>
      <span className='icon'>
        {type === 'danger' ? <DangerIcon /> : ''}
        {type === 'warning' ? <WarningIcon /> : ''}
        {type === 'info' ? <InfoIcon /> : ''}
        {type === 'success' ? <SuccessIcon /> : ''}
      </span>
      <span>{content}</span>
    </div>
  )
}

export default Alert