import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../styles/components/settingsCard.module.scss";

interface Props {
  title: string;
  data: any;
  updateCallback?: any;
}

type OptionType = {
  title: string;
  default: string;
  setValue: any;
  options?: any[];
  type: string;
  requiredField: boolean;
};

function SettingsCard({ title, data, updateCallback }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.settingsCard}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.content}>
        {data.map((option: OptionType, idx: number) => (
          <div key={idx} className={styles.formGroup}>
            <label htmlFor={option.title}>{option.title} {option.requiredField && <span className="required-icon">*</span>}</label>
            {option.type === "select" && option.options ? (
              <select
                onChange={(e) => option.setValue(e.target.value)}
                value={option.default}
                id={option.title}
              >
                {option.options.map((choice, idx) => (
                  <option key={idx} value={choice.value}>
                    {choice.title}
                  </option>
                ))}
              </select>
            ) : (
              <input
                onChange={(e) => option.setValue(e.target.value)}
                type={option.type}
                value={option.default}
              />
            )}
          </div>
        ))}
        {updateCallback && (
          <button style={{ marginBottom: "1em" }} onClick={updateCallback}>
            {t("Update")}
          </button>
        )}
      </div>
    </div>
  );
}

export default SettingsCard;
