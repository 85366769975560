import { useEffect, useState } from "react";
import axios from "axios";
import i18next from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import SettingsCard from "../../components/SettingsCard.component";
import { PreferencesContext } from "../../context/PreferencesContext";
import DashboardLayout from "../../layouts/DashboardLayout.layout";
import {
  GeneralApiAxiosPrivate,
  GeneralDataApiAxios,
} from "../../config/axios";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import { toast } from "react-toastify";

function Settings() {
  const [isLoading, setLoading] = useState(true);
  const [stages, setStages] = useState<Array<any>>([]);
  const [goverments, setGoverments] = useState([]);
  const { setTheme, setLanguage, language } = useContext(PreferencesContext);
  const { t } = useTranslation();

  // User Data
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [avatar, setAvatar] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [parentNumber, setParentNumber] = useState();
  const [schoolName, setSchoolName] = useState();
  const [gender, setGender] = useState();
  const [goverment, setGoverment] = useState();
  const [stage, setStage] = useState();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.get("/auth/me");
        let {
          name: fetchedName,
          email: fetchedEmail,
          school_name: fetchedSchoolName,
          phone_number: fetchedPhoneNumber,
          parent_number: fetchedParentNumber,
          gender: fetchedGender,
          stage: fetchedStage,
          goverment: fetchedGoverment,
        } = data.data;

        setName(fetchedName);
        setEmail(fetchedEmail);
        setSchoolName(fetchedSchoolName);
        setPhoneNumber(fetchedPhoneNumber);
        setParentNumber(fetchedParentNumber);
        setGender(fetchedGender);
        setStage(fetchedStage);
        setGoverment(fetchedGoverment);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, []);

  useEffect(() => {
    const askApi = async () => {
      try {
        const stagesResponse = await GeneralDataApiAxios.get(`/stages`);
        const govermentsResponse = await GeneralDataApiAxios.get(`/goverments`);
        const preparedStages = stagesResponse.data.data;
        const preparedGoverments = govermentsResponse.data.data;

        preparedStages.forEach((stage: any, idx: number) => {
          stage["title"] =
            language === "en"
              ? preparedStages[idx].name_en
              : preparedStages[idx].name_ar;

          stage["value"] = preparedStages[idx].name_en;

          delete stage["id"];
          delete stage["name_en"];
          delete stage["name_ar"];
          delete stage["created_at"];
          delete stage["updated_at"];
        });

        preparedGoverments.forEach((goverment: any, idx: number) => {
          goverment["title"] =
            language === "en"
              ? preparedGoverments[idx].name_en
              : preparedGoverments[idx].name_ar;

          goverment["value"] = preparedGoverments[idx].name_en;

          delete goverment["id"];
          delete goverment["name_en"];
          delete goverment["name_ar"];
          delete goverment["created_at"];
          delete goverment["updated_at"];
        });

        setStages(preparedStages);
        setGoverments(preparedGoverments);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [language]);

  const setLanguageCallback = (value: string) => {
    localStorage.setItem("lang", value);
    i18next.changeLanguage(value);
    axios.defaults.headers.common["Accept-Language"] = value;
    setLanguage(value);
  };

  const setThemeCallback = (value: string) => {
    localStorage.setItem("theme", value);
    if (value === "dark")
      document.body.style.background = "var(--dark-mode-background)";
    else document.body.style.background = "#fff";
    setTheme(value);
  };

  const updateCallback = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.put('/user', {
          full_name: name,
          email,
          password,
          password_confirmation: password,
          school_name: schoolName,
          phone_number: phoneNumber,
          parent_number: parentNumber,
          gender,
          stage_id: stage,
          goverment_id: goverment,
          current_password: oldPassword
        });
        
        localStorage.setItem('user', JSON.stringify(data.data));
      } catch (e: any) {
        console.error(e);
        toast.error(e.response.data.message);
      }
  };

  const settings = [
    {
      title: t("User Preferences"),
      data: [
        {
          title: t("Languages"),
          default: localStorage.getItem("lang") || "en",
          setValue: setLanguageCallback,
          type: "select",
          options: [
            {
              title: "English",
              value: "en",
            },
            {
              title: "العربية",
              value: "ar",
            },
          ],
        },
        {
          title: t("Theme"),
          default: localStorage.getItem("theme") || "light",
          setValue: setThemeCallback,
          type: "select",
          options: [
            {
              title: t("Light"),
              value: "light",
            },
            {
              title: t("Dark"),
              value: "dark",
            },
          ],
        },
      ],
    },
    {
      title: t("User Info"),
      updateCallback,
      data: [
        {
          title: t("name"),
          default: name,
          setValue: setName,
          type: "text",
        },
        {
          title: t("email"),
          default: email,
          setValue: setEmail,
          type: "text",
        },
        {
          title: t("password"),
          default: "",
          setValue: setPassword,
          type: "text",
        },
        {
          title: t("Avatar"),
          default: avatar,
          setValue: setAvatar,
          type: "file",
        },
        {
          title: t("phone_number"),
          default: phoneNumber,
          setValue: setPhoneNumber,
          type: "text",
        },
        {
          title: t("parent_number"),
          default: parentNumber,
          setValue: setParentNumber,
          type: "text",
        },
        {
          title: t("school_name"),
          default: schoolName,
          setValue: setSchoolName,
          type: "text",
        },
        {
          title: t("gender"),
          default: gender,
          setValue: setGender,
          type: "select",
          options: [
            {
              title: t("Male"),
              value: "male",
            },
            {
              title: t("Female"),
              value: "female",
            },
          ],
        },
        {
          title: t("goverment"),
          default: goverment,
          setValue: setGoverment,
          options: goverments,
          type: "select",
        },
        {
          title: t("stage"),
          default: stage,
          setValue: setStage,
          options: stages,
          type: "select",
        },
        {
          title: t("Old Password"),
          default: "",
          setValue: setOldPassword,
          type: "text",
          requiredField: true
        },
      ],
    },
  ];

  return (
    <DashboardLayout>
      {!isLoading ? (
        settings.map((setting: any, idx) => (
          <SettingsCard
            key={idx}
            title={setting.title}
            data={setting.data}
            updateCallback={setting.updateCallback ?? null}
          />
        ))
      ) : (
        <LoadingSpinner />
      )}
    </DashboardLayout>
  );
}

export default Settings;
