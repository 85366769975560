import React from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Link } from 'react-router-dom';

import Facebook from '../static/svg/facebook-icon.svg';
import Instagram from '../static/svg/instagram-icon.svg';

import styles from '../styles/components/footer.module.scss';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <div className={styles.cards}>
        <div className={styles.card}>
          <h2 className={styles.card_title}>{t('about')}</h2>
          <div className={styles.card_content}>
            <p>{parse(t('section.about.short_description'))}</p>
          </div>
        </div>
        <div className={styles.card}>
          <h2 className={styles.card_title}>{t('Links')}</h2>
          <div className={styles.card_content}>
            <ul>
              <li><Link to="/">{t('home')}</Link></li>
              <li><Link to="/pages/about">{t('about')}</Link></li>
              <li><Link to="/courses">{t('courses')}</Link></li>
              <li><Link to="/teachers">{t('teachers')}</Link></li>
            </ul>
          </div>
        </div>
        <div className={styles.card}>
          <h2 className={styles.card_title}>{t('Legal')}</h2>
          <div className={styles.card_content}>
            <ul>
              <li><Link to="/pages/privacy-policy">{t('Privacy Policy')}</Link></li>
              <li><Link to="/pages/terms-conditions">{t('Terms and Conditions')}</Link></li>
            </ul>
          </div>
        </div>
        <div className={styles.card}>
          <h2 className={styles.card_title}>{t('Social Media')}</h2>
          <div className={`${styles.card_content} ${styles.footer_icons}`}>
            <span className="icon">
              <a href="https://www.facebook.com/myedutime.official" target="_blank">
                <img src={Facebook} alt="facebook" />
              </a>
            </span>
            <span className="icon">
              <a href="https://www.instagram.com/myedutime/" target="_blank">
                <img src={Instagram} alt="Instagram" />
              </a>
            </span>
          </div>
        </div>
      </div>
      <hr />
      <p>{parse(t('footer.copy_right'))}</p>
    </footer>
  )
}

export default Footer