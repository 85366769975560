import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../components/Alert.component";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import { GeneralApiAxiosPrivate } from "../../config/axios";
import { UserContext } from "../../context/UserContext";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";
import axios from "axios";

function Payment() {
  const [amount, setAmount] = useState(70);
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [cards, setCards] = useState<any[]>();
  const [previousCard, setPreviousCard] = useState(false);
  const [formType, setFormType] = useState("Previous card");
  const [alert, setAlert] = useState<any>();
  const [isLoading, setLoading] = useState(true);
  const { updateBalance } = useContext(UserContext);
  const [fees, setFees] = useState(0);
  const [feesLoading, setFeesLoading] = useState(true);
  const [notEnoughAmount, setNotEnoughAmount] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.get('/card');
        setCards(data.data);
        setPreviousCard(data.data[0].id);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
    
    askApi()
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await GeneralApiAxiosPrivate.post("/card", {
        number: cardNumber,
        exp_month: expiryMonth,
        exp_year: expiryYear,
        cvc: cvv,
      });

      setAlert({
        'type': 'success',
        'content': t('card_added')
      });
    } catch (e: any) {
      console.log(e);
      setAlert({
        'type': 'danger',
        'content': e.response.data.message
      });
    }

    setLoading(false);
  };

  const buyWithPreviousCard = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await GeneralApiAxiosPrivate.post("/wallet", {
        amount,
        card: previousCard
      });

      updateBalance(amount);
      setAlert({
        'type': 'success',
        'content': t('deposit_made')
      });
    } catch (e: any) {
      console.log(e);
      setAlert({
        'type': 'danger',
        'content': e.response.data.message
      });
    }
    
    setLoading(false);
  }

  useEffect(() => {
    const feesApi = async () => {
      if (amount >= 50) {
        setNotEnoughAmount(false);
        try {
          const { data: feesData } = await axios.get('https://api.exchangerate.host/latest?base=USD&amount=0.3&symbols=EGP&places=1');
          setFees(Math.ceil((amount * 0.029) + feesData.rates.EGP));
          setFeesLoading(false);
        } catch (e) {
          console.error(e);
        }
      } else {
        setFeesLoading(true);
        setNotEnoughAmount(true);
      }
    }

    feesApi();
  }, [amount]);

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("payment.title")}</h1>
          <p>{t("payment.description")}</p>
        </div>
      </section>
      <section className={styles.formSection}>
        <div className={styles.alerts}>
          {!feesLoading && <Alert type="info" content={t('fees_message', { fees })} />}
          {alert && <Alert type={alert.type} content={alert.content} /> }
          {notEnoughAmount && <Alert type="warning" content={t('The Amount should be at least 50 EGP.')} />}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="options">{t("options")}</label>
          <select
            value={formType}
            onChange={(e) => setFormType(e.target.value)}
            id="options"
          >
            <option value="Previous card">{t('previous_card')}</option>
            <option value="Add card">{t('add_card')}</option>
          </select>
        </div>

        {formType === "Previous card" && (
          isLoading ? (
            <div className="loading-wrapper">
              <LoadingSpinner />
            </div>
          ) : (
            <form onSubmit={buyWithPreviousCard}>
              <div className={styles.formGroup}>
                <label htmlFor="amount">{t("amount")}</label>
                <input
                  value={amount}
                  id="amount"
                  type="number"
                  placeholder={t("Amount")!}
                  onChange={(e: any) => setAmount(e.target.value)}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="card_number">{t("card")}</label>
                <select onChange={(e: any) => setPreviousCard(e.target.value)}>
                  {cards && cards.map((card: any, idx: number) => (
                    <option value={card.id}>Visa {card.last4}</option>
                  ))}
                </select>
                <button>{t("deposit")}</button>
              </div>
            </form>
          )
        )}

        {formType === "Add card" && (
          isLoading ? (
            <div className="loading-wrapper">
              <LoadingSpinner />
            </div>
          ) : (
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="card_number">{t("card_number")}</label>
              <input
                id="card_number"
                type="number"
                placeholder={t("card_number")!}
                onInput={(event: any) =>
                  event.target.value.length > 16
                    ? (event.target.value = event.target.value.slice(0, 16))
                    : event.target.value
                }
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
            </div>

            <section className={styles.flexSection}>
              <div className={styles.formGroup}>
                <label htmlFor="exp_month">{t("expiry_month")}</label>
                <input
                  id="exp_month"
                  type="number"
                  placeholder="MM"
                  onInput={(event: any) =>
                    event.target.value.length > 2
                      ? (event.target.value = event.target.value.slice(0, 2))
                      : event.target.value
                  }
                  value={expiryMonth}
                  onChange={(e) => setExpiryMonth(e.target.value)}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="exp_year">{t("expiry_year")}</label>
                <input
                  id="exp_year"
                  type="number"
                  placeholder="YYYY"
                  onInput={(event: any) =>
                    event.target.value.length > 4
                      ? (event.target.value = event.target.value.slice(0, 4))
                      : event.target.value
                  }
                  value={expiryYear}
                  onChange={(e) => setExpiryYear(e.target.value)}
                />
              </div>
            </section>

            <div className={styles.formGroup}>
              <label htmlFor="cvv">{t("CVV")}</label>
              <input
                id="cvv"
                type="number"
                placeholder={t("CVV")!}
                onInput={(event: any) =>
                  event.target.value.length > 3
                    ? (event.target.value = event.target.value.slice(0, 3))
                    : event.target.value
                }
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </div>
            <button>{t("add_card")}</button>
          </form>
          )
        )}
      </section>
    </DashboardLayout>
  );
}

export default Payment;
