import Course from "./pages/Course.page";
import Courses from "./pages/Courses.page";
import Dashboard from "./pages/Dashboard/Dashboard.page";
import Payment from "./pages/Dashboard/Payment.page";
import Settings from "./pages/Dashboard/Settings.page";
import Withdraw from "./pages/Dashboard/Withdraw.page";
import DynamicPage from "./pages/DynamicPage.page";
import Home from "./pages/Home.page";
import Exam from "./pages/LectureContent/Exam/Exam.page";
import ExamResults from "./pages/LectureContent/Exam/Results.page";
import StartExam from "./pages/LectureContent/Exam/StartExam.page";
import Video from "./pages/LectureContent/Video.page";
import Login from "./pages/Login.page";
import Register from "./pages/Register.page";
import Teacher from "./pages/Teacher.page";
import AddAttachmentToLecture from "./pages/Teacher/AddAttachmentToLecture.page";
import AddExamToLecture from "./pages/Teacher/AddExamToLecture.page";
import AddVideosToLecture from "./pages/Teacher/AddVideosToLecture.page";
import CoursesList from "./pages/Teacher/CoursesList.page";
import CreateCourse from "./pages/Teacher/CreateCourse.page";
import CreateLecture from "./pages/Teacher/CreateLecture.page";
import LectureContent from "./pages/Teacher/LectureContent.page";
import LecturesList from "./pages/Teacher/LecturesList.page";
import Registeration from "./pages/Teacher/Registeration.page";
import Teachers from "./pages/Teachers.page";

const routes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Courses",
    path: "/courses",
    component: <Courses />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Course",
    path: "/course/:slug",
    component: <Course />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Dynamic Pages",
    path: "/pages/:slug",
    component: <DynamicPage />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Teachers",
    path: "/teachers",
    component: <Teachers />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Teacher",
    path: "/teacher/:id",
    component: <Teacher />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Login",
    path: "/login",
    component: <Login />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Register",
    path: "/register",
    component: <Register />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: <Dashboard />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Payment Dashboard",
    path: "/dashboard/payment",
    component: <Payment />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Withdraw Dashboard",
    path: "/dashboard/withdraw",
    component: <Withdraw />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Settings Dashboard",
    path: "/dashboard/settings",
    component: <Settings />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Watch Video",
    path: "/video/:slug/watch",
    component: <Video />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Exam",
    path: "/exam/:slug",
    component: <Exam />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Exam Start",
    path: "/exam/:code/start",
    component: <StartExam />,
    meta: {
      Authentication: true
    }
  },
  {
    name: "Exam Results",
    path: "/exam/:code/results",
    component: <ExamResults />,
    meta: {
      Authentication: true
    }
  },

  // Teacher Portal
  {
    name: "Teacher Registeration",
    path: "/teacher/complete-registeration/:token",
    component: <Registeration />,
    meta: {
      Authentication: false
    }
  },
  {
    name: "Teacher Courses",
    path: "/dashboard/teacher/courses",
    component: <CoursesList />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Lectures",
    path: "/dashboard/teacher/courses/:slug/lectures",
    component: <LecturesList />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Create Lecture",
    path: "/dashboard/teacher/courses/:slug/lectures/create",
    component: <CreateLecture />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Lecture Content",
    path: "/dashboard/teacher/lectures/:id",
    component: <LectureContent />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Lectures Create",
    path: "/dashboard/teacher/lectures",
    component: <CreateLecture />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Add Video To Lecture",
    path: "/dashboard/teacher/lectures/:id/videos/create",
    component: <AddVideosToLecture />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Add Attachments To Lecture",
    path: "/dashboard/teacher/lectures/:id/attachments/create",
    component: <AddAttachmentToLecture />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Add Exams To Lecture",
    path: "/dashboard/teacher/lectures/:id/exams/create",
    component: <AddExamToLecture />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
  {
    name: "Teacher Create Course",
    path: "/dashboard/teacher/courses/create",
    component: <CreateCourse />,
    meta: {
      Authentication: true,
      TeacherRoute: true
    }
  },
];

export default routes