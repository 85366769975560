import React, { useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  MdArrowDropDown,
  MdDashboard,
  MdLogout,
  FiMenu,
} from "../../config/icons";

import useAuth from "../../hooks/useAuth";
import { PreferencesContext } from "../../context/PreferencesContext";
import Logo from "../../static/img/korasti.png";
import LogoAr from "../../static/img/korasti_ar.png";

import styles from "../../styles/components/header.module.scss";

function Header() {
  const header = useRef<any>();
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const { language } = useContext(PreferencesContext);

  const { t } = useTranslation();
  const location = useLocation();
  const { isLoggedIn, Logout } = useAuth();

  const userInfo = JSON.parse(localStorage.getItem("user")!);

  const routes = [
    [t('home'), "/"],
    [t('courses'), "/courses"],
    [t('teachers'), "/teachers"],
    [t('about'), "/pages/about"],
  ];

  const toggleNavbar = () => {
    if (isOpened) {
      header.current.classList.remove(styles.opened);
      header.current.classList.add(styles.closed);
    } else {
      header.current.classList.add(styles.opened);
      header.current.classList.remove(styles.closed);
    }

    setOpened(!isOpened);
  };

  return (
    <header ref={header} className={styles.header}>
      <Link to="/">
        <div className={styles.logo}>
          {language === "en" ? <img src={Logo} alt="Logo" /> : <img src={LogoAr} alt="Logo" />}
        </div>
      </Link>
      <ul>
        {routes.map((route, idx) => (
          <li
            key={idx}
            className={route[1] === location.pathname ? styles.active : ""}
          >
            <Link to={route[1]}>{route[0]}</Link>
          </li>
        ))}
        <div className={styles.mobileLinks}>
          {!isLoggedIn ? (
            <Link className={styles.login_btn} to="/login">
              {t('Login')}
            </Link>
          ) : (
            <div className={styles.dropdown}>
              <div
                onClick={() => setDropdownOpened(!dropdownOpened)}
                className={styles.userAccount}
              >
                <h3 className={styles.name}>{userInfo?.name}</h3>
                <span className={styles.icon}>
                  <MdArrowDropDown />
                </span>
              </div>
              <div
                className={`${styles.menu} ${
                  !dropdownOpened ? styles.hidden : ""
                }`}
              >
                <ul>
                  <Link to="/dashboard">
                    <li>
                      <MdDashboard /> Dashboard
                    </li>
                  </Link>
                  <li onClick={Logout}>
                    <MdLogout /> Logout
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </ul>
      {!isLoggedIn ? (
        <Link className={styles.login_btn} to="/login">
          {t('Login')}
        </Link>
      ) : (
        <div className={styles.dropdown}>
          <div
            onClick={() => setDropdownOpened(!dropdownOpened)}
            className={styles.userAccount}
          >
            <h3 className={styles.name}>{userInfo?.name}</h3>
            <span className={styles.icon}>
              <MdArrowDropDown />
            </span>
          </div>
          <div
            className={`${styles.menu} ${!dropdownOpened ? styles.hidden : ""}`}
          >
            <ul>
              <Link to="/dashboard">
                <li>
                  <MdDashboard /> {t('Dashboard')}
                </li>
              </Link>
              <li onClick={Logout}>
                <MdLogout /> {t('Logout')}
              </li>
            </ul>
          </div>
        </div>
      )}
      <span onClick={toggleNavbar} className={styles.burgerIcon}>
        <FiMenu />
      </span>
    </header>
  );
}

export default Header;
