import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OwlCarousel from "react-owl-carousel-rtl";

import MainLayout from "../layouts/MainLayout.layout";

import PageSection from "../components/PageSection.component";
import CoursesContainer from "../components/CoursesContainer.component";
import CourseCard from "../components/CourseCard.component";
import { UserContext } from "../context/UserContext";
import { GeneralApiAxios } from "../config/axios";
import LoadingSpinner from "../components/LoadingSpinner.component";
import { PreferencesContext } from "../context/PreferencesContext";

import About from "../static/svg/about.svg";
import styles from "../styles/pages/home.module.scss";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function Home() {
  const [courses, setCourses] = useState<any[]>([]);
  const [isCoursesLoading, setCoursesLoading] = useState(true);
  const { language: lang } = useContext(PreferencesContext);

  const { isLoggedIn } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxios.get(
          "/course?filter[is_special]=true"
        );
        setCourses(data.data.course);
      } catch (e) {
        console.error(e);
      }
      setCoursesLoading(false);
    };

    askApi();
  }, [setCoursesLoading, setCourses]);

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <MainLayout>
      <section className={styles.showcase}>
        <h1>{t("MyEdutime")}</h1>
        <p>"{t("showcase.description")}"</p>
        <Link to={!isLoggedIn ? "/login" : "/courses"}>
          {t("showcase.startnow")}
        </Link>
      </section>
      <PageSection
        title={t("section.courses.title")}
        description={t("section.courses.description")}
      >
        {!isCoursesLoading && courses ? (
          <OwlCarousel
            items={isMobile ? 1 : undefined}
            loop={courses.length > 3 ? true : false}
            margin={10}
            nav={false}
            rtl={true}
            center={true}
            autoPlay={true}
            lazyLoad={true}
            autoplayHoverPause={true}
          >
            {courses.map((course, idx) => (
              <div key={idx} className="item">
                <CourseCard
                  image={course.image}
                  instructor={{
                    image: course.teacher.avatar
                      ? course.teacher.avatar
                      : `https://ui-avatars.com/api/?name=${course.teacher.name}&background=38b6ff&color=fff&format=svg`,
                    name: course.teacher.name,
                    is_account_verified: course.teacher.is_account_verified
                  }}
                  stage={
                    lang == "en" ? course.stage.name_en : course.stage.name_ar
                  }
                  isSpecial={course.is_special}
                  courseName={course.name}
                  lecturesCount={course.lecture_count}
                  link={`/course/${course.slug}`}
                />
              </div>
            ))}
          </OwlCarousel>
        ) : (
          <div className={styles.coursesLoadingSpinner}>
            <LoadingSpinner />
          </div>
        )}
      </PageSection>
    </MainLayout>
  );
}

export default Home;
