import React, { useState } from 'react'

const PreferencesContext = React.createContext<any>({});

interface Props {
  children: any;
}

const storedLanguage = localStorage.getItem('lang') || 'ar';
const storedTheme = localStorage.getItem("theme") || "light";

const PreferencesProvider = ({ children }: Props) => {
  const [language, setLanguage] = useState(storedLanguage);
  const [theme, setTheme] = useState(storedTheme);

  return (
    <PreferencesContext.Provider value={{ language, setLanguage, theme, setTheme}}>
      {children}
    </PreferencesContext.Provider>
  );
}

export { PreferencesProvider, PreferencesContext };