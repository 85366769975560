import React from 'react';
import parse from 'html-react-parser'

import styles from '../styles/components/pageSection.module.scss';

interface Props {
  title: string;
  description: string;
  children: any
}

function PageSection({ title, description, children }: Props) {
  return (
    <section className={styles.pageSection}>
      <div className={styles.titleCard}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{parse(description)}</p>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </section>
  )
}

export default PageSection