import React from 'react'
import { useTranslation } from 'react-i18next';
import { MdVideoLibrary } from '../config/icons'

import styles from '../styles/components/courseContent.module.scss'

interface Props {
  children: any;
  lectureCount: number;
}

function CourseContent({ children, lectureCount }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.courseContentWrapper}>
      <h1 className={styles.title}>
        <span className={styles.icon}><MdVideoLibrary /></span>
        <span className={styles.text}>{t('section.courses.lectures.title')}</span>
      </h1>
      {children}
    </div>
  )
}

export default CourseContent