import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";

import MainLayout from "../../../layouts/MainLayout.layout";
import { BiAlarmExclamation } from "../../../config/icons";

import styles from "../../../styles/pages/startExam.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralApiAxiosPrivate } from "../../../config/axios";
import LoadingSpinner from "../../../components/LoadingSpinner.component";

interface rendererProps {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

function StartExam() {
  const [isLoading, setLoading] = useState(true);
  const [endTime, setEndTime] = useState("Mar 06, 2023 07:55:14");
  const [questions, setQuestions] = useState([]);
  let answers: any[] = [];
  const { code } = useParams();

  const endDate = new Date(endTime);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const Completionist = () => (
    <span className={styles.timesUp}>
      <BiAlarmExclamation />
      {t("exam.time_is_up")}
    </span>
  );

  const renderer = ({ hours, minutes, seconds, completed }: rendererProps) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.get(`/exam/${code}/questions`);
        const { end_time, exam } = data.data;
        setQuestions(exam.questions);
        setLoading(false);
        setEndTime(end_time);
      } catch (e) {
        console.error(e);
      }
    }

    askApi();
  }, [code])

  const answerHandler = (event: any, questionID: number) => {
    const allAnswers =
      event.target.parentElement.querySelectorAll("[data-type=answer]");
    const answer = event.target;

    const filtered = answers.filter(function (el) {
      return el.id != questionID;
    });

    answers = filtered;

    for (let i = 0; i < allAnswers.length; i++) {
      let singleAnswer = allAnswers[i];
      singleAnswer.classList.remove(styles.chosenAnswer);
    }

    answer.classList.add(styles.chosenAnswer);
    const answerObject = {
      id: questionID,
      answer: answer.innerHTML,
    };

    const answersObject = [...answers, answerObject];
    answers = answersObject;
  };

  const handelSubmit = async () => {
    const { data } = await GeneralApiAxiosPrivate.post(`/exam/${code}/submit`, { questions: answers });
    navigate(`/exam/${data.data.exam_slug}/results`);
  };

  return (
    <MainLayout>
      <nav className={styles.examNavigationBar}>
        <h1>Exam Name</h1>
        <span>
          <Countdown date={endDate} renderer={renderer} />
        </span>
      </nav>
      <section className={styles.examQuestions}>
        {!isLoading ? (
          questions && questions.map((question: any, idx) => (
            <div key={idx} className={styles.questionCard}>
              <div className={styles.question}>
                <span>{++idx}.</span>
                <span>{question.question}</span>
              </div>
              <div className={styles.answers}>
                {question.answers.map((answer: string, idx: number) => (
                  <div
                    onClick={(event) => answerHandler(event, question.id)}
                    className={styles.answer}
                    data-type="answer"
                  >
                    {answer}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className='page-loading-wrapper'>
            <LoadingSpinner />
          </div>
        )}
        <button onClick={handelSubmit} className="btn btn-primary">
          Submit Exam
        </button>
      </section>
    </MainLayout>
  );
}

export default StartExam;
