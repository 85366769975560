import React, { useContext, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { PreferencesContext } from '../context/PreferencesContext';

function ThemeManager() {
  const storedTheme = localStorage.getItem('theme') || 'light';

  useEffect(() => {
    if (storedTheme === 'dark') document.body.style.background = 'var(--dark-mode-background)';
  }, [storedTheme]);

  return <Outlet />;
}

export default ThemeManager