import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import { AiFillCloseCircle } from "../../config/icons";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import formStyles from "../../styles/pages/payment.module.scss";
import styles from "../../styles/pages/Teacher/createExam.module.scss";

interface AnswersProps {
  answer: string;
}

interface ExamProps<T> {
  question: string;
  correct_answer: string;
  answers: Array<AnswersProps>;
}

function AddExamToLecture() {
  const [examName, setExamName] = useState<string>();
  const [time, setTime] = useState<number>(60);
  const [passRequired, setPassRequired] = useState(false);
  const [examContent, setExamContent] = useState<any[]>([]);
  const [error, setError] = useState();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleQuestionAdd = () => {
    setExamContent([
      ...examContent,
      {
        question: "",
        correct_answer: "",
        answers: [],
      },
    ]);
  };

  const handleAnswerAdd = (index: number) => {
    const questions = [...examContent];
    const fetchedQuestion = questions[index];
    const questionAnswers = [...fetchedQuestion["answers"], { answer: "" }];
    fetchedQuestion.answers = questionAnswers;
    questions.splice(index, 1);
    questions.splice(index, 0, fetchedQuestion);
    setExamContent(questions);
  };

  const handleQuestionRemove = (index: number) => {
    const questions = [...examContent];
    questions.splice(index, 1);
    setExamContent(questions);
  };

  const handleAnswerRemove = (index: number, answerIndex: number) => {
    const questions = [...examContent];
    const fetchedQuestion: any = questions[index];
    fetchedQuestion.answers.splice(answerIndex, 1);

    setExamContent(questions);
  };

  const handleQuestionChange = (e: any, index: number) => {
    const questions = [...examContent];
    questions[index].question = e.target.value;
    setExamContent(questions);
  };

  const handleCorrectAnswerChange = (e: any, index: number) => {
    const questions = [...examContent];
    questions[index].correct_answer = e.target.value;
    setExamContent(questions);
  };

  const handleAnswerChange = (e: any, index: number, answerIdx: number) => {
    const questions = [...examContent];
    questions[index].answers[answerIdx] = e.target.value;
    setExamContent(questions);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      await TeachersApiAxiosPrivate.post("/lecture/exams", {
        pass_required: passRequired,
        time: time * 60,
        lecture_id: id,
        content: examContent,
      });

      navigate(`/dashboard/teacher/lectures/${id}`);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <section className={formStyles.pageTitleSection}>
        <div className={formStyles.content}>
          <h1>{t("lecture.exams.create.title")}</h1>
          <p>{t("lecture.exams.create.description")}</p>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <section className={formStyles.formSection}>
          {error && (
            <div className={styles.alerts}>
              <Alert type="danger" content={error} />
            </div>
          )}
          <div className={formStyles.formGroup}>
            <label htmlFor="exam_name">{t("exam_name")}</label>
            <input
              value={examName}
              onChange={(e) => setExamName(e.target.value)}
              id="exam_name"
              type="text"
              placeholder={t("exam_name")!}
            />
          </div>

          <div className={formStyles.formGroup}>
            <label htmlFor="time">{t("time_in_minutes")}</label>
            <input
              value={time}
              onChange={(e: any) => setTime(e.target.value)}
              id="time"
              type="number"
              placeholder={t("time_in_minutes")!}
            />
          </div>

          {examContent &&
            examContent.map((element: ExamProps<{}>, idx) => (
              <div key={idx} className={formStyles.formGroup}>
                <div className={styles.formGroupInputWrapper}>
                  <label htmlFor="question">{t("question")}</label>
                  <div className={styles.questionContainer}>
                    <input
                      onChange={(event) => handleQuestionChange(event, idx)}
                      value={element.question}
                      id="question"
                      type="text"
                      placeholder={t("question")!}
                    />
                    <span
                      onClick={() => handleQuestionRemove(idx)}
                      className={styles.closeBtn}
                    >
                      <AiFillCloseCircle />
                    </span>
                  </div>
                </div>
                <div className={styles.questionContent}>
                  <div
                    className={`${styles.formGroupInputWrapper} ${styles.correctAnswerFormGroup}`}
                  >
                    <label htmlFor="correct_answer">
                      {t("correct_answer")}
                    </label>
                    <div className={styles.questionContainer}>
                      <input
                        onChange={(event) =>
                          handleCorrectAnswerChange(event, idx)
                        }
                        value={element.correct_answer}
                        id="correct_answer"
                        type="text"
                        placeholder={t("correct_answer")!}
                      />
                    </div>
                  </div>
                  <div className={styles.answers}>
                    <label htmlFor="answers">{`${t("answers")}:`}</label>
                    {element.answers &&
                      element.answers.map(({ answer }, answerIdx) => (
                        <div key={answerIdx} className={styles.answerContainer}>
                          <input
                            onChange={(event) =>
                              handleAnswerChange(event, idx, answerIdx)
                            }
                            value={answer}
                            type="text"
                          />
                          <span
                            onClick={() => handleAnswerRemove(idx, answerIdx)}
                            className={styles.closeBtn}
                          >
                            <AiFillCloseCircle />
                          </span>
                        </div>
                      ))}
                    <button
                      type="button"
                      onClick={() => handleAnswerAdd(idx)}
                      className={styles.addAnswerBtn}
                    >
                      Add Answer
                    </button>
                  </div>
                </div>
              </div>
            ))}

          <div className={styles.formGroup}>
            <input
              onChange={(e: any) => setPassRequired(e.target.checked)}
              style={{ marginRight: "3px" }}
              id="pass_required"
              type="checkbox"
            />
            <label htmlFor="pass_required">{t("pass_required")}</label>
          </div>

          <div className={`${formStyles.formGroup} ${styles.examQuestions}`}>
            <button
              type="button"
              onClick={handleQuestionAdd}
              className="btn btn-secondary"
            >
              Add Question
            </button>
          </div>

          <button type="submit" className="btn btn-primary">
            {t("lecture.exams.create.title")}
          </button>
        </section>
      </form>
    </DashboardLayout>
  );
}

export default AddExamToLecture;
