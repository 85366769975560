import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert.component";
import { GENERAL_API, GENERAL_DATA_API, STUDENTS_API } from "../config/api";
import { PreferencesContext } from "../context/PreferencesContext";
import useAuth from "../hooks/useAuth";

import Logo from "../static/img/korasti.png";
import LogoAr from "../static/img/korasti_ar.png";
import RegisterPicture from "../static/svg/register-picture.svg";

import styles from "../styles/pages/login.module.scss";

function Register() {
  // Form Data
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [gender, setGender] = useState("male");
  const [school, setSchool] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [parentPhoneNumber, setParentPhoneNumber] = useState("");
  const [goverment, setGoverment] = useState("1");
  const [stage, setStage] = useState("");

  // General Data
  const [stages, setStages] = useState([]);
  const [goverments, setGoverments] = useState([]);
  const { language } = useContext(PreferencesContext);

  const { setLoggedIn, setToken } = useAuth();
  const naviagte = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      const stagesResponse = await axios.get(`${GENERAL_DATA_API}/stages`);
      const govermentsResponse = await axios.get(
        `${GENERAL_DATA_API}/goverments`
      );
      setStages(stagesResponse.data.data);
      setGoverments(govermentsResponse.data.data);
    };

    askApi();
  }, []);

  const FormSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(`${STUDENTS_API}/auth/register`, {
        full_name: `${firstName} ${lastName}`,
        email,
        password,
        password_confirmation: passwordConfirmation,
        school_name: school,
        phone_number: phoneNumber,
        parent_number: parentPhoneNumber,
        gender,
        stage_id: stage,
        goverment_id: goverment
      });

      const { token, user } = data.data;

      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
      axios.defaults.headers.common['Authorization'] = token;

      setLoggedIn(true);
      setToken(token);
      naviagte('/');
    } catch (e: any) {
      setError(e.response.data.message);
      setPassword('');
      setPasswordConfirmation('');
      console.error(e);
    }
  }

  return (
    <div className={styles.loginPageBody}>
      <section className={`${styles.leftSide} ${styles.registerLeftSide}`}>
        <Link className={styles.logoLink} to="/">
          <div className={styles.logo}>
            {language === "en" ? <img src={Logo} alt="logo" /> : <img src={LogoAr} alt="logo" />}
          </div>
        </Link>
        <form onSubmit={FormSubmit}>
          <h1 className={styles.title}>{t("register.title")}</h1>
          <p className={styles.description}>{t("register.description")}</p>
          <div className={styles.alerts}>
            {error && <Alert type="danger" content={error} />}
          </div>
          <section className={styles.FlexSection}>
            <div className={styles.formGroup}>
              <label htmlFor="first_name">{t("first_name")}</label>
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" id="first_name" placeholder="John" />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="last_name">{t("last_name")}</label>
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" id="last_name" placeholder="Doe" />
            </div>
          </section>
          <div className={styles.formGroup}>
            <label htmlFor="email">{t("email")}</label>
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" id="email" placeholder="ahmed@myedutime.com" />
          </div>
          <section className={styles.FlexSection}>
            <div className={styles.formGroup}>
              <label htmlFor="password">{t("password")}</label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                placeholder="Enter your password"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password_confirmation">
                {t("password_confirmation")}
              </label>
              <input
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                type="password"
                id="password_confirmation"
                placeholder="Enter your password again"
              />
            </div>
          </section>
          <div className={styles.formGroup}>
            <label htmlFor="gender">{t("gender")}</label>
            <select id="gender" value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="male">{t("gender.male")}</option>
              <option value="female">{t("gender.female")}</option>
            </select>
          </div>
          <section className={styles.FlexSection}>
            <div className={styles.formGroup}>
              <label htmlFor="school_name">{t("school_name")}</label>
              <input
                value={school}
                onChange={(e) => setSchool(e.target.value)}
                type="text"
                id="school_name"
                placeholder="Enter your school name"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="stage">{t("stage")}</label>
              <select id="stage" value={stage} onChange={(e) => setStage(e.target.value)}>
                {stages &&
                  stages.map((stage: any) => (
                    <option value={stage.id} key={stage.id}>
                      {language == "en" ? stage.name_en : stage.name_ar}
                    </option>
                  ))}
              </select>
            </div>
          </section>
          <section className={styles.FlexSection}>
            <div className={styles.formGroup}>
              <label htmlFor="phone_number">{t("phone_number")}</label>
              <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="text" id="phone_number" placeholder="01234567890" />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="parent_number">{t("parent_number")}</label>
              <input value={parentPhoneNumber} onChange={(e) => setParentPhoneNumber(e.target.value)} type="text" id="parent_number" placeholder="01234567890" />
            </div>
          </section>
          <div className={styles.formGroup}>
            <label htmlFor="goverment">{t("goverment")}</label>
            <select id="goverment" value={goverment} onChange={(e) => setGoverment(e.target.value)}>
              {goverments &&
                goverments.map((goverment: any) => (
                  <option value={goverment.id} key={goverment.id}>
                    {language == "en" ? goverment.name_en : goverment.name_ar}
                  </option>
                ))}
            </select>
          </div>
          <Link to="/login">{t("Already Have an Account?")}</Link>
          <button type="submit">{t("register.title")}</button>
        </form>
      </section>
      <section className={styles.rightSide}>
        <img src={RegisterPicture} alt="Register Picture" />
      </section>
    </div>
  );
}

export default Register;
