import React, { useState, useEffect, useContext } from "react";
import CourseCard from "../components/CourseCard.component";
import CoursesContainer from "../components/CoursesContainer.component";
import CoursesFilter from "../components/CoursesFilter.component";
import CoursesPageCotainer from "../components/CoursesPageCotainer.component";
import LoadingSpinner from "../components/LoadingSpinner.component";
import { GeneralApiAxios } from "../config/axios";
import { PreferencesContext } from "../context/PreferencesContext";
import MainLayout from "../layouts/MainLayout.layout";

import styles from "../styles/pages/courses.module.scss";
import Showcase from "../components/Showcase.component";
import { useTranslation } from "react-i18next";
import NoData from "../components/NoData.component";

function Courses() {
  const { t } = useTranslation();

  const [courses, setCourses] = useState<any[]>([]);
  const [isCoursesLoading, setCoursesLoading] = useState(true);
  
  const [search, setSearch] = useState('');
  const [stage, setStage] = useState('');
  const [subject, setSubject] = useState('');
  
  const {language: lang} = useContext(PreferencesContext); 

  useEffect(() => {
    setCoursesLoading(true);
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxios.get(
          `/course?filter[name]=${search}&filter[stage.id]=${stage}&filter[teacher.teacherInfo.subject_id]=${subject}`
        );
        setCourses(data.data.course);
        setCoursesLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [search, stage, subject, setCourses, setCoursesLoading]);

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxios.get("/course");
        setCourses(data.data.course);
        setCoursesLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [setCourses, setCoursesLoading]);

  return (
    <MainLayout>
      <Showcase title={t('Courses')} />
      <div className={styles.coursesPageContent}>
        <CoursesFilter
          setSearch={setSearch}
          setStage={setStage}
          setSubject={setSubject}
        />
        <CoursesPageCotainer>
          {!isCoursesLoading ? (
            courses.length > 0 ?
              courses.map((course, idx) => (
                <CourseCard
                  key={idx}
                  image={course.image}
                  instructor={{
                    image: course.teacher.avatar
                      ? course.teacher.avatar
                      : `https://ui-avatars.com/api/?name=${course.teacher.name}&background=38b6ff&color=fff&format=svg`,
                    name: course.teacher.name,
                    is_account_verified: course.teacher.is_account_verified
                  }}
                  stage={lang === 'ar' ? course.stage.name_ar : course.stage.name_en}
                  isSpecial={course.is_special}
                  courseName={course.name}
                  lecturesCount={course.lecture_count}
                  link={`/course/${course.slug}`}
                />
              ))
            : (
              <NoData />
            )
          ) : (
            <div className={styles.coursesLoadingSpinner}>
              <LoadingSpinner />
            </div>
          )}
        </CoursesPageCotainer>
      </div>
    </MainLayout>
  );
}

export default Courses;
