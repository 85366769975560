import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  AiFillStar,
  BsPlayCircle,
  MdOutlineKeyboardArrowRight,
} from "../config/icons";

import styles from "../styles/components/courseCard.module.scss";

interface Props {
  image: string;
  instructor: {
    image: string;
    name: string;
    is_account_verified: boolean;
  };
  stage: string;
  isSpecial: boolean;
  courseName: string;
  lecturesCount: number;
  link: string;
}

function CourseCard({
  image,
  instructor,
  stage,
  isSpecial,
  courseName,
  lecturesCount,
  link,
}: Props) {
  const { t } = useTranslation();

  return (
    <Link className={styles.courseCardLink} to={link}>
      <div className={styles.courseCard}>
        <div className={styles.courseImage}>
          {isSpecial && (
            <span
              title={t("section.courses.featured.star.title")!}
              className={styles.featuredStar}
            >
              <AiFillStar />
            </span>
          )}
          <img src={image} alt={courseName} />
        </div>
        <div className={styles.courseText}>
          <span className={styles.stage}>{stage}</span>
          <h1 className={styles.courseName}>{courseName}</h1>
          <span className={styles.lecturesCountContainer}>
            <BsPlayCircle />{" "}
            <span className={styles.lecturesCount}>{lecturesCount}</span>{" "}
            <span>{t("section.courses.lectures")}</span>
          </span>
          <hr />
          <div className={styles.instructor}>
            <div className={styles.instructorInfo}>
              <img
                className={styles.instructorImage}
                src={instructor.image}
                alt={courseName}
              />
              <h3 className={`${styles.instructorName} ${instructor.is_account_verified ? 'verified-user' : ''}`}>{instructor.name}</h3>
              {instructor.is_account_verified && <i className="verification-tick"></i>}
            </div>
            <span className={styles.navigationButton}>
              <MdOutlineKeyboardArrowRight />
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CourseCard;
