import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";

function CreateLecture() {
  const [lectureName, setLectureName] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const { t } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      await TeachersApiAxiosPrivate.post("/lecture", {
        name: lectureName,
        price,
        course_slug: slug,
      });

      navigate(`/dashboard/teacher/courses/${slug}/lectures`);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
    }
  };

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("lecture.create.title")}</h1>
          <p>{t("lecture.create.description")}</p>
        </div>
      </section>
      <section className={styles.formSection}>
        {error && (
          <div className={styles.alerts}>
            <Alert type="danger" content={error} />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="lecture_name">{t("lecture_name")}</label>
            <input
              onChange={(e) => setLectureName(e.target.value)}
              id="lecture_name"
              type="text"
              placeholder={t("lecture_name")!}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="price">{t("price")}</label>
            <input
              onChange={(e: any) => setPrice(e.target.value)}
              id="price"
              type="number"
              placeholder={t("price")!}
            />
          </div>

          <button className="btn btn-primary">
            {t("lecture.create.title")}
          </button>
        </form>
      </section>
    </DashboardLayout>
  );
}

export default CreateLecture;
