import React from 'react';
import videojs from 'video.js';

//@ts-ignore
import dynamicWatermark from 'videojs-dynamic-watermark';

import 'video.js/dist/video-js.css';

export const VideoJS = (props: any) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<any>(null);
  const {options, onReady} = props;

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      videojs.registerPlugin('dynamicWatermark', dynamicWatermark);
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoElement.classList.add('vjs-fluid');
      videoElement.classList.add('vjs-16-9');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;
      
      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    player.dynamicWatermark({
      elementId: props.code,
      watermarkText: props.code,
      changeDuration: 5000,
      cssText: "display: inline-block; color: #fff; background-color: transparent; font-size: 1rem; z-index: 9999; position: absolute; border: 1px solid #fff; padding: .7em 2em; border-radius: 6px; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
    });

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ 
      maxWidth: "1000px",
      maxHeight: "500px",
      margin: "2em auto 6em",
     }}>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJS;