import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert.component";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import { TeachersApiAxios } from "../../config/axios";
import useAuth from "../../hooks/useAuth";
import { PreferencesContext } from "../../context/PreferencesContext";

import Logo from "../../static/img/korasti.png";
import LogoAr from "../../static/img/korasti_ar.png";
import RegisterPicture from "../../static/svg/teacherregisteration.svg";

import styles from "../../styles/pages/login.module.scss";

function Registeration() {
  const { language } = useContext(PreferencesContext);

  // Form Data
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [title, setTitle] = useState<string>("Mr");

  const { setLoggedIn, setToken } = useAuth();
  const naviagte = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  const { token: slug } = useParams();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await TeachersApiAxios.get(`/register/${slug}`);
        const { name, email, taxes, subject } = data.data;

        setName(name);
        setEmail(email);
        setLoading(false);
      } catch (e) {
        console.error(e);
        naviagte("/register");
      }
    };

    askApi();
  }, [setName, setEmail, setLoading]);

  const FormSubmit = async (e: any) => {
    e.preventDefault();

    try {
      const { data } = await TeachersApiAxios.post(`/register/${slug}`, {
        password,
        password_confirmation: passwordConfirmation,
        phone_number: phoneNumber,
        title,
      });

      const { token, user } = data.data;

      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      axios.defaults.headers.common["Authorization"] = token;

      setLoggedIn(true);
      setToken(token);
      naviagte("/");
    } catch (e: any) {
      setError(e.response.data.message);
      setPassword("");
      setPasswordConfirmation("");
      console.error(e);
    }
  };

  return !isLoading ? (
    <div className={`${styles.loginPageBody}`}>
      <section
        className={`${styles.leftSide} ${styles.teacherRegisterationPageBody}`}
      >
        <Link className={styles.logoLink} to="/">
          <div className={styles.logo}>
            {language == 'en' ? <img src={Logo} alt="logo" /> : <img src={LogoAr} alt="logo" />}
          </div>
        </Link>
        <form onSubmit={FormSubmit}>
          <h1 className={styles.title}>{t("teacher.registeration.title")}</h1>
          <p className={styles.description}>{t("register.description")}</p>
          <div className={styles.alerts}>
            {error && <Alert type="danger" content={error} />}
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="first_name">{t("name")}</label>
            <input
              value={name}
              type="text"
              id="first_name"
              placeholder="John"
              disabled
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="email">{t("email")}</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              id="email"
              placeholder="ahmed@korasti.com"
              disabled
            />
          </div>
          <section className={styles.FlexSection}>
            <div className={styles.formGroup}>
              <label htmlFor="password">{t("password")}</label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                placeholder="Enter your password"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password_confirmation">
                {t("password_confirmation")}
              </label>
              <input
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                type="password"
                id="password_confirmation"
                placeholder="Enter your password again"
              />
            </div>
          </section>
          <div className={styles.formGroup}>
            <label htmlFor="phone_number">{t("phone_number")}</label>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="text"
              id="phone_number"
              placeholder="01234567890"
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="title">{t("Title")}</label>
            <select onChange={(e) => setTitle(e.target.value)} id="title">
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Proff">Proff</option>
              <option value="Dr">Dr</option>
            </select>
          </div>
          <button type="submit">{t("register.title")}</button>
        </form>
      </section>
      <section className={styles.rightSide}>
        <img src={RegisterPicture} alt="Register Picture" />
      </section>
    </div>
  ) : (
    <div className="page-loading-wrapper">
      <LoadingSpinner />
    </div>
  );
}

export default Registeration;
