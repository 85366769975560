import React from 'react'

import styles from '../styles/components/showcase.module.scss'

interface Props {
  title: string;
}

function Showcase({ title }: Props) {
  return (
    <div className={styles.showcase}>
      <h1>{title}</h1>
    </div>
  )
}

export default Showcase