import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes";
import axios from "axios";
import i18next from "i18next";
import "./i18n";
import RequireAuth from "./components/RequireAuth.component";
import PersistLogin from "./components/PersistLogin.component";
import ThemeManager from "./components/ThemeManager.component";
import Error404 from "./pages/errors/Error404";
import { PreferencesContext } from "./context/PreferencesContext";
import RequireTeacher from "./components/Teacher/RequireTeacher.component";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const lang = localStorage.getItem("lang") || "ar";

axios.defaults.headers.common["Accept-Language"] = lang;
i18next.changeLanguage(lang);

function App() {
  const { theme, language, setLanguage } = useContext(PreferencesContext);

  useEffect(() => {
    setLanguage(language);
    document.head.setAttribute('lang', language);
  }, [language]);

  return (
    <div
      style={{
        direction: language == "en" ? "ltr" : "rtl",
      }}
      className={`${theme === "light" ? "" : "dark-mode"} ${language === "ar" ? "arabic-version" : ""}`}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<ThemeManager />}>
            <Route element={<PersistLogin />}>
              {routes.map((route, key) =>
                route.meta.Authentication ? (
                  !route.meta.TeacherRoute ? (
                    <Route key={key} element={<RequireAuth />}>
                      <Route path={route.path} element={route.component} />
                    </Route>
                  ) : (
                    <Route key={key} element={<RequireTeacher />}>
                      <Route path={route.path} element={route.component} />
                    </Route>
                  )
                ) : (
                  <Route
                    key={key}
                    path={route.path}
                    element={route.component}
                  />
                )
              )}
              <Route path="*" element={<Error404 />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer rtl={language === 'ar'} />
    </div>
  );
}

export default App;
