import { Outlet, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { GeneralApiAxios } from "../config/axios";
import Loading from "./Loading.component";

function PersistLogin() {
  const [isLoading, setLoading] = useState(true);
  const {setLoggedIn, setUser, Logout} = useAuth();
  const token = localStorage.getItem('token');
  const location = useLocation();
  const pathNameFirstPart = location.pathname.split('/')[1];

  useEffect(() => {
    const askApi = async () => {
      if (token) {
        try {
          const { data } = await GeneralApiAxios.get(`/auth/me`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          const user = data.data;
  
          localStorage.setItem('user', JSON.stringify(user));
          setLoggedIn(true);
          setUser(user);
        } catch (e) {
          console.error(e);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          setLoggedIn(false);
        }
      }

      setLoading(false);
    }

    askApi();
  }, [token]);

  return (
    !isLoading ? <Outlet /> : (
      pathNameFirstPart !== 'dashboard' && pathNameFirstPart !== 'exam' && pathNameFirstPart !== 'video'
        ? <Loading><Outlet /></Loading>
        : <Loading />
    )
  );
}

export default PersistLogin