import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CourseContent from "../components/CourseContent.component";
import Lecture from "../components/Lecture.component";
import LoadingSpinner from "../components/LoadingSpinner.component";
import { GeneralApiAxios } from "../config/axios";
import MainLayout from "../layouts/MainLayout.layout";

import VerificationMark from '../static/img/verification-mark.png';

import styles from "../styles/pages/course.module.scss";
import NoData from "../components/NoData.component";

interface Lecture {
  id: number;
  name: string;
  price: number;
  is_purchased: boolean;
}

interface Course {
  name: string;
  description: string;
  image: string;
  teacher: {
    id: string;
    name: string;
    avatar: string;
    is_account_verified: boolean;
  },
  lecture_count: number;
  lectures: Lecture[]
}

function Course() {
  const navigate = useNavigate();
  const [course, setCourse] = useState<Course>();
  const [isLoading, setLoading] = useState(true);
  const token = localStorage.getItem('token') || false;

  const { slug } = useParams();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxios.get(`/course/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setCourse(data.data.course);
        setLoading(false);
        console.log(data)
      } catch (e) {
        console.error(e);
      }
    }

    askApi();
  }, []);

  const goToTeacher = (id: string) => navigate(`/teacher/${id}`);

  return (
    <MainLayout>
      {(!isLoading && course) ? (
        <div className={styles.pageContent}>
          <section className={styles.showcase}>
            <img src={course.image} alt={course.name} />
            <div className={styles.textCard}>
              <div className={styles.text}>
                <h2>{course.name}</h2>
                <p>{course.description}</p>
              </div>
              <div onClick={() => goToTeacher(course.teacher.id)} className={styles.instructor}>
                <img
                  className={styles.instructorImage}
                  src={course.teacher.avatar ? course.teacher.avatar : `https://ui-avatars.com/api/?name=${course.teacher.name}&background=38b6ff&color=fff&format=svg`}
                  alt={course.teacher.name}
                />
                <h3 className={`${styles.instructorName} ${course.teacher.is_account_verified ? 'verified-user' : ''}`}>{course.teacher.name}</h3>
                {course.teacher.is_account_verified && <i className="verification-tick"></i>}
              </div>
            </div>
          </section>
          <CourseContent lectureCount={course.lecture_count}>
            {course.lectures.length > 0 ? (
              course.lectures.map((lecture, idx) => (
                <Lecture key={idx} name={lecture.name} price={lecture.price} lectureId={lecture.id} isPurchased={lecture.is_purchased} />
              ))
            ) : (
              <div style={{ margin: '3rem 0' }}>
                <NoData />
              </div>
            )}
          </CourseContent>
        </div>
      ) : (
        <div className='page-loading-wrapper'>
          <LoadingSpinner />
        </div>
      )}
    </MainLayout>
  );
}

export default Course;
