import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BsPlayCircle } from '../config/icons';

import styles from '../styles/components/courseCard.module.scss';

interface Props {
  teacherID: number;
  image: string;
  instructorName: string;
  coursesCount: number;
  isAccountVerified: boolean;
}

function TeacherCard({ teacherID, image, instructorName, coursesCount, isAccountVerified }: Props) {
  const { t } = useTranslation();
  
  return (
    <Link className={styles.courseCardLink} to={`/teacher/${teacherID}`}>
      <div className={styles.courseCard}>
        <div className={styles.courseImage}>
          <img src={image} alt={instructorName} />
        </div>
        <div className={styles.courseText}>
          <div style={{ 
            marginBottom: '0.7em'
           }} className={styles.instructorInfo}>
            <h1 className={`${styles.teacherName} ${isAccountVerified ? 'verified-user' : ''}`}>{instructorName}</h1>
            {isAccountVerified && <i className="verification-tick"></i>}
          </div>
          <span className={styles.lecturesCountContainer}>
            <BsPlayCircle /> <span className={styles.lecturesCount}>{coursesCount}</span> <span>{coursesCount > 1 ? t('showcase.courses.link') : t('showcase.courses.single.link')}</span>
          </span>
        </div>
      </div>
    </Link>
  )
}

export default TeacherCard