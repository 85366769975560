import React from 'react'

import styles from '../styles/components/coursesContainer.module.scss';

interface Props {
  children: any
}

function TeachersContainer({ children }: Props) {
  return (
    <div className={styles.coursesContainer}>
      {children}
    </div>
  )
}

export default TeachersContainer