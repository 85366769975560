import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MdOutlineSlowMotionVideo, IoIosArrowForward } from '../../config/icons';

import styles from '../../styles/components/lecture.module.scss'

interface Props {
  lectureName: string;
  lecturePrice: number;
  lecturePurchases: number;
  lectureId: number;
}

function DashboardLecture({ lectureName, lecturePrice, lecturePurchases, lectureId }: Props) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Link className={styles.LectureLink} to={`/dashboard/teacher/lectures/${lectureId}`}>
    <div className={styles.lectureCard} onClick={(e) => setOpen(true)}>
      <div className={styles.mainSide}>
        <span className={`${styles.lectureIcon} ${open ? styles.dark : ''}`}>
          <MdOutlineSlowMotionVideo />
        </span>
        <section className={styles.text}>
          <h3 className={styles.lectureTitle}>{lectureName}</h3>
          <p className={styles.lecturePurchase}>{lecturePrice} {t('EGP')} | {lecturePurchases} {t('purchases')}</p>
        </section>
      </div>
      <IoIosArrowForward />
    </div>
    </Link>
  )
}

export default DashboardLecture