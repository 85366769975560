import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../components/Alert.component";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";
import dashboardStyles from "../../styles/pages/Teacher/dashboard.module.scss";

function Withdraw() {
  const [withdrawals, setWithdrawals] = useState<any[]>([]);

  const [amount, setAmount] = useState(70);
  const [formType, setFormType] = useState("bank");
  const [iban, setIban] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await TeachersApiAxiosPrivate.get("/money-withdrawal");
        setWithdrawals(data.data);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    askApi();
  }, [setWithdrawals]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const formData =
        formType === "bank"
          ? { amount, method: "bank", iban }
          : { amount, method: "bank", vodafone_cash_number: phoneNumber };
      const { data } = await TeachersApiAxiosPrivate.post(
        "/money-withdrawal",
        formData
      );

      console.log(data);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
    }
  };

  const deleteWithdrawalRequest = async (id: number) => {
    setLoading(true);
    try {
      await TeachersApiAxiosPrivate.delete(`/money-withdrawal/${id}`);
      const newWithdrawals = withdrawals.filter((request) => {
        return request.id !== id;
      });

      setWithdrawals(newWithdrawals);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("withdraw.title")}</h1>
          <p>{t("withdraw.description")}</p>
        </div>
      </section>
      {!isLoading ? (
        <>
          <section className={styles.formSection}>
            {error && (
              <div className={styles.alerts}>
                <Alert type="danger" content={error} />
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="amount">{t("amount")}</label>
                <input
                  value={amount}
                  onChange={(e: any) => setAmount(e.target.value)}
                  id="amount"
                  type="number"
                  placeholder={t("Amount")!}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="options">{t("options")}</label>
                <select
                  value={formType}
                  onChange={(e) => setFormType(e.target.value)}
                  id="options"
                >
                  <option value="bank">Bank</option>
                  <option value="vodafone cash">Vodafone Cash</option>
                </select>
              </div>

              {formType === "bank" && (
                <>
                  <div className={styles.formGroup}>
                    <label htmlFor="iban">{t("iban")}</label>
                    <input
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                      id="iban"
                      type="text"
                      placeholder={t("iban")!}
                      maxLength={34}
                    />
                  </div>
                  <button>{t("withdraw.title")}</button>
                </>
              )}

              {formType === "vodafone cash" && (
                <>
                  <div className={styles.formGroup}>
                    <label htmlFor="phone_number">{t("phone_number")}</label>
                    <input
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      id="phone_number"
                      type="text"
                      placeholder={t("phone_number")!}
                      maxLength={11}
                    />
                  </div>
                  <button>{t("withdraw.title")}</button>
                </>
              )}
            </form>
          </section>
          {withdrawals && withdrawals.length > 0 && (
            <section
              style={{ marginTop: "3em" }}
              className={dashboardStyles.dashboardSection}
            >
              <nav className={dashboardStyles.dashboardSectionNavigation}>
                <h1 className={dashboardStyles.sectionTitle}>
                  {t("withdrawals")}
                </h1>
              </nav>
              <hr />
              <div className={dashboardStyles.dashboardTableWrapper}>
                <table className={dashboardStyles.dashboardTable}>
                  <thead>
                    <tr>
                      <td>{t("method")}</td>
                      <td>{t("iban")}</td>
                      <td>{t("phone_number")}</td>
                      <td>{t("amount")}</td>
                      <td>{t("status")}</td>
                      <td>{t("actions")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawals.map((withdraw: any, idx: number) => (
                      <tr key={idx}>
                        <td>{withdraw.method}</td>
                        <td>{withdraw.iban}</td>
                        <td>{withdraw.vodafone_cash_number}</td>
                        <td>{withdraw.amount}</td>
                        <td>{withdraw.status}</td>
                        <td>
                          <button
                            onClick={() => deleteWithdrawalRequest(withdraw.id)}
                            className="btn btn-danger"
                          >
                            {t("remove")}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </>
      ) : (
        <div className="page-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </DashboardLayout>
  );
}

export default Withdraw;
