import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/payment.module.scss";

function AddVideosToLecture() {
  const [startedUploading, setStartedUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [name, setName] = useState<string>("");
  const [maxViews, setMaxViews] = useState<string>("string");
  const [video, setVideo] = useState<any>();
  const [slug, setSlug] = useState<string>("");
  const [error, setError] = useState<string>();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const lectureId: any = id;

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setStartedUploading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("max_views", maxViews);
      formData.append("video", video);
      formData.append("slug", slug);
      formData.append("lecture_id", lectureId);

      await TeachersApiAxiosPrivate.post("/lecture/video", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total!);
          setUploadProgress(percent);
        }
      });

      navigate(`/dashboard/teacher/lectures/${lectureId}`);
    } catch (e: any) {
      console.error(e);
      setError(e.response.data.message);
      setStartedUploading(false);
    }
  };

  return (
    <DashboardLayout>
      <section className={styles.pageTitleSection}>
        <div className={styles.content}>
          <h1>{t("lecture.videos.create.title")}</h1>
          <p>{t("lecture.videos.create.description")}</p>
        </div>
      </section>
      <form onSubmit={handleSubmit}>
        <section className={styles.formSection}>
          {error && (
            <div className={styles.alerts}>
              <Alert type="danger" content={error} />
            </div>
          )}
          <div className={styles.formGroup}>
            <label htmlFor="video_name">{t("video_name")}</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="video_name"
              type="text"
              placeholder={t("video_name")!}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="max_views">{t("max_views")}</label>
            <input
              value={maxViews}
              onChange={(e) => setMaxViews(e.target.value)}
              id="max_views"
              type="number"
              placeholder={t("max_views")!}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="video">{t("video")}</label>
            {!startedUploading ? (
              <input
                onChange={(e: any) => setVideo(e.target.files[0])}
                id="video"
                type="file"
              />
            ) : (
              <progress className={styles.progressBar} value={uploadProgress} max={100}>{uploadProgress}</progress>
            )}
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="slug">{t("slug")}</label>
            <input
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              id="slug"
              type="text"
              placeholder={t("slug")!}
            />
          </div>

          <button className="btn btn-primary" disabled={startedUploading}>
            {t("lecture.videos.create.title")}
          </button>
        </section>
      </form>
    </DashboardLayout>
  );
}

export default AddVideosToLecture;
