import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import videojs from "video.js";

import { GeneralApiAxiosPrivate } from "../../config/axios";
import useAuth from "../../hooks/useAuth";
import MainLayout from "../../layouts/MainLayout.layout";

import styles from "../../styles/pages/video.module.scss";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import VideoJS from "../../components/VideoJs";

function Video() {
  const playerRef = useRef<any>();
  const videoTag = useRef<any>();
  const videoContainer = useRef<any>();
  const [securityCode, setSecurityCode] = useState<string>("");
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [source, setSource] = useState("encrypted");
  const [youtubeVideoID, setYoutubeVideoID] = useState();
  const [videoURL, setVideoURL] = useState();
  const { slug } = useParams();
  const { user } = useAuth();
  const userID = user.id;
  const { t } = useTranslation();

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: "16:9",
    sources: [
      {
        src: videoURL,
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      videojs.log("player will dispose");
    });
  };

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await GeneralApiAxiosPrivate.get(
          `/video/${slug}&&${userID}`
        );
        const { url, source: videoSource, code } = data.data;
        setSource(videoSource);

        console.log(videoSource);

        if (videoSource == "local" || videoSource == "wasabi") {
          setSecurityCode(code);
          setVideoURL(url);
          setLoading(false);
        } else if (videoSource == 'bunny') {
          setVideoURL(url);
          setLoading(false);
        } else {
          setLoading(false);
          const videoID = url
            .split("/")
            .slice(-1)[0]
            .split("watch?v=")[1]
            .split("&t")[0];
          setYoutubeVideoID(videoID);
        }
      } catch (e) {
        console.error(e);
        setError(t("Couldn't Load Video try again or contact support."));
      }
    };

    askApi();
  }, []);

  return (
    <MainLayout>
      {isLoading ? (
        <div className="loading-wrapper">
          <LoadingSpinner />
        </div>
      ) : source && (source === "local" || source === "wasabi") ? (
        <VideoJS
          options={videoJsOptions}
          onReady={handlePlayerReady}
          code={securityCode}
        />
      ) : source === "bunny" ? (
        <div
          style={{ position: "relative", paddingTop: "33em", maxWidth: "1000px", maxHeight: "500px", right: 0, left: 0, margin: '0 auto', marginTop: "2em" }}
        >
          <iframe
            src={videoURL}
            loading="lazy"
            style={{
              border: "none",
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              maxWidth: "1000px",
              maxHeight: "500px"
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
          ></iframe>
        </div>
      ) : (
        <div ref={videoContainer} className={styles.videoContainer}>
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${youtubeVideoID}?rel=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </MainLayout>
  );
}

export default Video;
