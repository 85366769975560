import React, { useEffect, useState } from "react";
import LoadingSpinner from "../components/LoadingSpinner.component";
import TeacherCard from "../components/TeacherCard.component";
import TeachersContainer from "../components/TeachersContainer.component";
import { TeachersApiAxios } from "../config/axios";
import MainLayout from "../layouts/MainLayout.layout";

import styles from "../styles/pages/courses.module.scss";
import Showcase from "../components/Showcase.component";
import { useTranslation } from "react-i18next";
import NoData from "../components/NoData.component";

interface Teacher {
  id: number;
  avatar: string;
  name: string;
  courses_count: number;
  is_account_verified: boolean;
}

function Teachers() {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await TeachersApiAxios.get("/all");
        setTeachers(data.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [setTeachers, setLoading]);

  return (
    <MainLayout>
      <Showcase title={t('Teachers')} />
      <div style={{ gridTemplateColumns: "1fr" }} className={styles.coursesPageContent}>
          {!loading ? (
            teachers.length > 0 ? (
              <TeachersContainer>
                {teachers && teachers.map((teacher, idx) => (
                  <TeacherCard
                    key={idx}
                    teacherID={teacher.id}
                    image={teacher.avatar ? teacher.avatar : `https://ui-avatars.com/api/?name=${teacher.name}&background=38b6ff&color=fff&format=svg`}
                    instructorName={teacher.name}
                    coursesCount={teacher.courses_count}
                    isAccountVerified={teacher.is_account_verified}
                  />
                ))}
              </TeachersContainer>
            ) : (
              <NoData />
            )
          ) : (
            <div className={styles.coursesLoadingSpinner}>
              <LoadingSpinner />
            </div>
          )}
      </div>
    </MainLayout>
  );
}

export default Teachers;
