import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import CourseContent from "../../components/CourseContent.component";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import DashboardLecture from "../../components/Teacher/DashboardLecture.component";
import { TeachersApiAxiosPrivate } from "../../config/axios";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import styles from "../../styles/pages/Teacher/dashboard.module.scss";

function LecturesList() {
  const [lecturesCount, setLecturesCount] = useState(0);
  const [lectures, setLectures] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const { slug } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      const { data } = await TeachersApiAxiosPrivate.get(
        `/course/${slug}/lectures`
      );

      const { lectures, lectures_count } = data.data;

      setLectures(lectures);
      setLecturesCount(lectures_count);
      setLoading(false);
    };

    askApi();
  }, [setLectures, setLoading]);

  return (
    <DashboardLayout>
      <nav className={styles.teacherDashboardNavbar}>
        <h1>{t('lectures_list')}</h1>
        <Link
          className="btn btn-primary"
          to={`/dashboard/teacher/courses/${slug}/lectures/create`}
        >
          {t('lecture.create.title')}
        </Link>
      </nav>
      {isLoading ? (
        <div className="loading-wrapper">
          <LoadingSpinner />
        </div>
      ) : (
        <CourseContent lectureCount={lecturesCount}>
          {lectures &&
            lectures.map((lecture: any, idx: number) => (
              <DashboardLecture
                key={idx}
                lectureName={lecture.name}
                lecturePrice={lecture.price}
                lecturePurchases={lecture.purchases}
                lectureId={lecture.id}
              />
            ))}
        </CourseContent>
      )}
    </DashboardLayout>
  );
}

export default LecturesList;
