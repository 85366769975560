import axios from "axios";
import React, { useState } from "react";
import { GeneralApiAxios } from "../config/axios";

const UserContext = React.createContext<any>({});

interface Props {
  children: any;
}

const UserProvider = ({ children }: Props) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(false);
  const [user, setUser] = useState<any>({});

  const localStorageToken = localStorage.getItem("token") || false;

  const Logout = async () => {
    await GeneralApiAxios.get(`/auth/logout`, {
      headers: {
        Authorization: `Bearer ${localStorageToken}`,
      },
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setLoggedIn(false);
  };

  const updateBalance = (newBalance: number) =>
    (user.balance = parseFloat((+user.balance + newBalance).toString()).toFixed(2));

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        setLoggedIn,
        token,
        setToken,
        user,
        setUser,
        Logout,
        updateBalance,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
