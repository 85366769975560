import React, { useContext, useEffect, useState } from "react";
import {
  MdComputer,
  ImAttachment,
  HiDownload,
  GoPencil,
  FaWallet,
  SlCreditCard,
} from "../config/icons";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";

import { PreferencesContext } from "../context/PreferencesContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GeneralApiAxiosPrivate } from "../config/axios";
import Alert from "./Alert.component";
import LoadingSpinner from "./LoadingSpinner.component";
import Video from "./Video.component";

import styles from "../styles/components/lectureContent.module.scss";

interface Props {
  open: boolean;
  setOpen: any;
  lectureId: number;
  owned: boolean;
  setOwned: any;
}

function LectureContent({ open, setOpen, lectureId, owned, setOwned }: Props) {
  const [videos, setVideos] = useState<any[] | null>(null);
  const [exams, setExams] = useState<any[] | null>(null);
  const [attachments, setAttachments] = useState<any[] | null>(null);
  const [ableToWatchVideos, setAbleToWatchVideos] = useState(false);

  const [isContentLoading, setContentLoading] = useState(true);

  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [choosenMenu, setChoosenMenu] = useState("Videos");
  const [codeFormVisible, setCodeFormVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const closeModal = () => setOpen(false);
  const { theme } = useContext(PreferencesContext);
  const { t } = useTranslation();

  const menuTabs = ["Videos", "Exams", "Attachments"];

  const buyWithWallet = async () => {
    try {
      await GeneralApiAxiosPrivate.post(`/lecture/${lectureId}/buy`, {
        payment_method: "wallet",
      });

      setOwned(true);
    } catch (e: any) {
      console.error(e);
      const statusCode = e.response.status;

      if (statusCode === 401) {
        navigate("/login", {
          state: {
            from: location,
          },
        });
      }

      setError(e.response.data.message);
    }
  };

  const buyWithCode = () => setCodeFormVisible(true);

  const submitBuyWithCode = async (event: any) => {
    event.preventDefault();
    try {
      await GeneralApiAxiosPrivate.post(`/lecture/${lectureId}/buy`, {
        payment_method: "code",
        code,
      });

      setOwned(true);
    } catch (e: any) {
      console.error(e);
      if (e.response.status === 401) {
        navigate("/login", {
          state: {
            from: location,
          },
        });
      }
    }
  };

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data: lectureData } = await GeneralApiAxiosPrivate.get(
          `/lecture/${lectureId}`
        );

        const { videos, exams, attachments, finished_required_lectures } = lectureData.data;

        setVideos(videos);
        setExams(exams);
        setAttachments(attachments);
        setContentLoading(false);
        setAbleToWatchVideos(finished_required_lectures);
      } catch (e) {
        console.error(e);
      }
    };

    if (owned) askApi();
  }, [owned]);

  const handleWatchesFinished = () => {
    setOpen(false);
    toast.error(t("No More Watches Remaining"), {
      theme: "colored",
    });
  };

  const handleExamsNotSolved = () => {
    setOpen(false);
    toast.info(t("You still have some exams which are required before watching videos."));
  }

  return (
    <div className={`${styles.overlay} ${open ? styles.open : ""}`}>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className={styles.wrapper}>
          <div
            className={`${styles.lectureContent} ${
              theme === "dark" ? styles.darkLectureContent : ""
            }`}
          >
            {owned ? (
              <>
                <nav>
                  <ul>
                    {menuTabs.map((tab, idx) => (
                      <li
                        key={idx}
                        onClick={() => setChoosenMenu(tab)}
                        className={tab === choosenMenu ? styles.chosen : ""}
                      >
                        {tab}
                      </li>
                    ))}
                  </ul>
                </nav>
                {choosenMenu === "Videos" && (
                  <section className={styles.elementsWrapper}>
                    {(!isContentLoading && !ableToWatchVideos) && (
                      <div style={{ margin: "1em 0 .5em" }}>
                        <Alert type="info" content={t('You have some required exams to be able to watch the lecture videos')} />
                      </div>
                    )}
                    {!isContentLoading ? (
                      videos &&
                      videos.map((video, idx) =>
                        ableToWatchVideos ? (                          
                          video.watches.remaining_times == 0 ? (
                            <div onClick={handleWatchesFinished}>
                              <Video video={video} />
                            </div>
                          ) : (
                            <Link to={`/video/${video.slug}/watch`}>
                              <Video video={video} />
                            </Link>
                          )
                        ) : (
                          <div onClick={handleExamsNotSolved}>
                            <Video video={video} />
                          </div>
                        )
                      )
                    ) : (
                      <div className={styles.loadingContainer}>
                        <LoadingSpinner />
                      </div>
                    )}
                    <button onClick={closeModal}>Back</button>
                  </section>
                )}

                {choosenMenu === "Exams" && (
                  <section className={styles.elementsWrapper}>
                    {!isContentLoading ? (
                      exams &&
                      exams.map((exam, idx) => (
                        <Link to={`/exam/${exam.slug}`}>
                          <div key={idx} className={styles.element}>
                            <div className={styles.mainSection}>
                              <span className={styles.icon}>
                                <MdComputer />
                              </span>
                              <div className={styles.text}>
                                <h3>Exam {++idx}</h3>
                                <p>
                                  {exam.pass_required
                                    ? t("Passage Required")
                                    : t("Optional")}
                                </p>
                              </div>
                            </div>
                            <span className={styles.icon}>
                              <GoPencil />
                            </span>
                          </div>
                        </Link>
                      ))
                    ) : (
                      <div className={styles.loadingContainer}>
                        <LoadingSpinner />
                      </div>
                    )}
                  </section>
                )}

                {choosenMenu === "Attachments" && (
                  <section className={styles.elementsWrapper}>
                    {!isContentLoading ? (
                      attachments &&
                      attachments.map((attachment, idx) => (
                        <a
                          href={attachment.link}
                          download={`${attachment.name}.${
                            attachment.link.split(".")[1]
                          }`}
                          target="_blank"
                        >
                          <div key={idx} className={styles.element}>
                            <div className={styles.mainSection}>
                              <span className={styles.icon}>
                                <ImAttachment />
                              </span>
                              <div className={styles.text}>
                                <h3>{attachment.name}</h3>
                                <p>{attachment.type}</p>
                              </div>
                            </div>
                            <span className={styles.icon}>
                              <HiDownload />
                            </span>
                          </div>
                        </a>
                      ))
                    ) : (
                      <div className={styles.loadingContainer}>
                        <LoadingSpinner />
                      </div>
                    )}
                  </section>
                )}
              </>
            ) : (
              <div className={styles.purchaseLecture}>
                <div className={styles.titleContainer}>
                  <h1 className={styles.title}>{t("Purchase Lecture")}</h1>
                  <p>
                    Lectures Are Non Refundable So Make Sure Before Proceeding
                  </p>
                </div>
                <div className={styles.contentWrapper}>
                  <div className={styles.errors}>
                    {error && <Alert type="danger" content={error} />}
                  </div>
                  {!codeFormVisible ? (
                    <>
                      <div className={styles.content}>
                        <div
                          className={styles.optionCard}
                          onClick={buyWithWallet}
                        >
                          <FaWallet />
                          <h3 className="title">{t("wallet")}</h3>
                        </div>
                        <div
                          className={styles.optionCard}
                          onClick={buyWithCode}
                        >
                          <SlCreditCard />
                          <h3 className="title">{t("myedutime.card")}</h3>
                        </div>
                      </div>
                      <button onClick={closeModal}>Back</button>
                    </>
                  ) : (
                    <>
                      <div
                        className={`${styles.content} ${styles.codeFormContent}`}
                      >
                        <form onSubmit={submitBuyWithCode}>
                          <div className={styles.formControl}>
                            <input
                              onChange={(e) => setCode(e.target.value)}
                              type="text"
                              placeholder={t("code")!}
                            />
                          </div>
                          <button className="btn btn-secondary">
                            Purchase
                          </button>
                        </form>
                      </div>
                      <button onClick={() => setCodeFormVisible(false)}>
                        Back
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default LectureContent;
