import React from 'react'
import Footer from '../components/Footer.component'
import DashboardSidebar from './components/DashboardSidebar.component'
import Header from './components/Header.component'

interface Props {
  children: any
}

function DashboardLayout({ children }: Props) {
  return (
    <>
      <Header />
      <div className='layout-content dashboard-content'>
        <div className="layout-content-sidebar">
          <DashboardSidebar />
        </div>
        <div className="layout-content-body">
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DashboardLayout