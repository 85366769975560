import React, { useState, useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import parse from "html-react-parser";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import MainLayout from "../layouts/MainLayout.layout";

import styles from "../styles/pages/dynamicpage.module.scss";
import { GENERAL_API } from "../config/api";

import "../styles/helpers/ckeditor.css";
import LoadingSpinner from "../components/LoadingSpinner.component";
import Showcase from "../components/Showcase.component";
import { useTranslation } from "react-i18next";

function DynamicPage() {
  const [pageContent, setPageContent] = useState<string>("");
  const [isLoading, setLoading] = useState(true);
  let { slug } = useParams();
  let navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const response = await axios.get(`${GENERAL_API}/page/${slug}`);
        const { content, head } = response.data.data;

        document.head.innerHTML += head;
        setPageContent(content);
        setLoading(false);
      } catch (e) {
        console.error(e);
        return navigate("/404");
      }
    };

    askApi();
  }, [slug, setPageContent]);

  return (
    <MainLayout>
    {isLoading ? (
      <div className='page-loading-wrapper'>
        <LoadingSpinner />
      </div>
    ) : (
      <>
        {slug === 'about' && (
          <Showcase title={t('About Korasti')} />
        )}
        <div className={styles.pageContent}>
          <div className="ck-content">{parse(pageContent)}</div>
        </div>
      </>
    )}
    </MainLayout>
  );
}

export default DynamicPage;
