import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseCard from "../components/CourseCard.component";
import CoursesPageCotainer from "../components/CoursesPageCotainer.component";
import LoadingSpinner from "../components/LoadingSpinner.component";
import { TeachersApiAxios } from "../config/axios";
import { PreferencesContext } from "../context/PreferencesContext";
import MainLayout from "../layouts/MainLayout.layout";

import PlaceholderImage from "../static/placeholder/course.jpg";

import styles from "../styles/pages/course.module.scss";
import { useTranslation } from "react-i18next";

function Teacher() {
  const [teacherData, setTeacherData] = useState<any>({});
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const { language: lang } = useContext(PreferencesContext);
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await TeachersApiAxios.get(`/${id}`);
        setTeacherData(data.data);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, [setTeacherData, setLoading]);

  return (
    <MainLayout>
      {!isLoading ? (
        <div className={styles.pageContent}>
          {teacherData.role === 'teacher' ? (
            <section className={styles.showcase}>
              <img src={teacherData.avatar ? teacherData.avatar : `https://ui-avatars.com/api/?name=${teacherData.name}&background=38b6ff&color=fff&format=svg`} alt={teacherData.name} />
              <div className={styles.textCard}>
                <div className={styles.text}>
                  <h2>
                    {teacherData.title}. {teacherData.name}
                  </h2>
                  <ul>
                    <li>
                      <span>{t('Subject')}</span>: {teacherData.subject}
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          ) : (
            <section className={`${styles.showcase} ${styles.adminShowcase}`}>
              <img className={styles.adminPicture} src={teacherData.avatar ? teacherData.avatar : `https://ui-avatars.com/api/?name=${teacherData.name}&background=38b6ff&color=fff&format=svg`} alt={teacherData.name} />
              <div className={styles.textCard}>
                <div className={styles.text}>
                  <h2 className={teacherData.is_account_verified ? 'verified-user' : ''}>
                    {teacherData.name}
                  </h2>
                  {teacherData.is_account_verified && <i style={{ 
                    display: 'block',
                   }} className="verification-tick"></i>}
                  <ul>
                  </ul>
                </div>
              </div>
            </section>
          )}
          <h1 className={styles.coursesTitle}>{t('Courses')} ({teacherData.courses_count}):</h1>
          <div className={styles.coursesContainer}>
            <CoursesPageCotainer>
              {teacherData &&
                teacherData.courses.map((course: any, idx: number) => (
                  <CourseCard
                    key={idx}
                    image={course.image}
                    instructor={{
                      image: teacherData.avatar
                        ? teacherData.avatar
                        : `https://ui-avatars.com/api/?name=${teacherData.name}&background=38b6ff&color=fff&format=svg`,
                      name: teacherData.name,
                      is_account_verified: course.teacher.is_account_verified
                    }}
                    stage={
                      lang === "ar"
                        ? course.stage.name_ar
                        : course.stage.name_en
                    }
                    isSpecial={course.is_special}
                    courseName={course.name}
                    lecturesCount={course.lecture_count}
                    link={`/course/${course.slug}`}
                  />
                ))}
            </CoursesPageCotainer>
          </div>
        </div>
      ) : (
        <div className={styles.coursesLoadingSpinner}>
          <LoadingSpinner />
        </div>
      )}
    </MainLayout>
  );
}

export default Teacher;
