import axios from 'axios'
import { GENERAL_DATA_API, GENERAL_API, STUDENTS_API, TEACHERS_API } from './api';

const storedLanguage = localStorage.getItem('lang') || 'en';

const GeneralDataApiAxios = axios.create({
  baseURL: GENERAL_DATA_API,
  headers: {
    'Accept-Language': storedLanguage
  }
});

const GeneralApiAxios = axios.create({
  baseURL: GENERAL_API,
  headers: {
    'Accept-Language': storedLanguage
  }
});

const StudentApiAxios = axios.create({
  baseURL: STUDENTS_API,
  headers: {
    'Accept-Language': storedLanguage
  }
});

const TeachersApiAxios = axios.create({
  baseURL: TEACHERS_API,
  headers: {
    'Accept-Language': storedLanguage
  }
});

const token = localStorage.getItem('token') || false;
const GeneralApiAxiosPrivate = axios.create({
  baseURL: GENERAL_API,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Accept-Language': storedLanguage
  },
});

const StudentApiAxiosPrivate = axios.create({
  baseURL: STUDENTS_API,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Accept-Language': storedLanguage
  },
});

const TeachersApiAxiosPrivate = axios.create({
  baseURL: TEACHERS_API,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Accept-Language': storedLanguage
  },
});

export {
  GeneralDataApiAxios,
  GeneralApiAxios,
  StudentApiAxios,
  TeachersApiAxios,

  GeneralApiAxiosPrivate,
  StudentApiAxiosPrivate,
  TeachersApiAxiosPrivate
};