import React from 'react'
import { useTranslation } from 'react-i18next';

import NoDataImage from '../static/svg/nodata.svg';

import styles from '../styles/components/nodata.module.scss'

function NoData() {
  const { t } = useTranslation();

  return (
    <div className={styles.noDataContainer}>
      <img src={NoDataImage} alt="no data" />
      <div className={styles.text}>
        <h3>{t('No Data Found!')}</h3>
        <p>{t('We have nothing to show at the time, Come back later!')}</p>
      </div>
    </div>
  )
}

export default NoData