import React from 'react'
import Footer from '../components/Footer.component'
import Header from './components/Header.component'

interface Props {
  children: any
}

function MainLayout({ children }: Props) {
  return (
    <>
      <Header />
      <div className='layout-content'>
        {children}
      </div>
      <Footer />
    </>
  )
}

export default MainLayout