import React from 'react'

import LoadingSpinnerImage from '../static/img/loading2.gif';

function LoadingSpinner() {
  return (
    <div className="loading-wrapper">
      <img src={LoadingSpinnerImage} alt="Loading Spinner" />
    </div>
  )
}

export default LoadingSpinner