import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout.layout'

import { ReactComponent as ErrorLogo } from '../../static/svg/404.svg';

import styles from '../../styles/pages/errors/error404.module.scss';

function Error404() {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <div className={styles.errorPageWrapper}>
        <span className={styles.errorCode}><ErrorLogo /></span>
        <h1 style={{ textAlign: "center" }}>{t('Something went wrong')}</h1>
        <p>{t('404.description')}</p>
        <Link className="btn btn-primary" to="/">{t('Home Page')}</Link>
      </div>
    </MainLayout>
  )
}

export default Error404