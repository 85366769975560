import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireTeacher = () => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();

  return isLoggedIn && (user.role === "teacher" || user.role === "admin") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireTeacher;
