import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CourseCard from "../../components/CourseCard.component";
import CoursesPageCotainer from "../../components/CoursesPageCotainer.component";
import LoadingSpinner from "../../components/LoadingSpinner.component";
import {
  GeneralApiAxios,
  GeneralApiAxiosPrivate,
  TeachersApiAxiosPrivate,
} from "../../config/axios";
import { PreferencesContext } from "../../context/PreferencesContext";
import DashboardLayout from "../../layouts/DashboardLayout.layout";

import PlaceholderImage from "../../static/placeholder/course.jpg";

import styles from "../../styles/pages/Teacher/dashboard.module.scss";

function CoursesList() {
  const [courses, setCourses] = useState<any[]>([]);
  const [isCoursesLoading, setCoursesLoading] = useState(true);
  const { language: lang } = useContext(PreferencesContext);
  const { t } = useTranslation();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await TeachersApiAxiosPrivate.get("/course/all");
        setCourses(data.data);
      } catch (e) {
        console.error(e);
      }
      setCoursesLoading(false);
    };

    askApi();
  }, [setCourses, setCoursesLoading]);

  return (
    <DashboardLayout>
      <nav className={styles.teacherDashboardNavbar}>
        <h1>{t('Courses List')}</h1>
        <Link
          className="btn btn-primary"
          to="/dashboard/teacher/courses/create"
        >
          {t('lecture.courses.create.title')}
        </Link>
      </nav>
      <CoursesPageCotainer>
        {!isCoursesLoading ? (
          courses &&
          courses.map((course, idx) => (
            <CourseCard
              key={idx}
              image={course.image}
              instructor={{
                image: course.teacher.avatar
                  ? course.teacher.avatar
                  : `https://ui-avatars.com/api/?name=${course.teacher.name}&background=38b6ff&color=fff&format=svg`,
                name: course.teacher.name,
                is_account_verified: course.teacher.is_account_verified
              }}
              stage={lang == "en" ? course.stage.name_en : course.stage.name_ar}
              isSpecial={course.is_special}
              courseName={course.name}
              lecturesCount={course.lecture_count}
              link={`/dashboard/teacher/courses/${course.slug}/lectures`}
            />
          ))
        ) : (
          <div className="loading-wrapper">
            <LoadingSpinner />
          </div>
        )}
      </CoursesPageCotainer>
    </DashboardLayout>
  );
}

export default CoursesList;
