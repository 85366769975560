import { useEffect, useState } from 'react';
import { MdOutlineSlowMotionVideo, IoIosArrowForward } from '../config/icons';

import styles from '../styles/components/lecture.module.scss'
import LectureContent from './LectureContent.component';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  name: string;
  lectureId: number;
  isPurchased: boolean;
  price: number;
}

function Lecture({ name, lectureId, isPurchased, price }: Props) {
  const [purchased, setPurchased] = useState(isPurchased);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (price: any, is_purchased: any) => {
    if (price == '0.00' && !is_purchased) {
      navigate('/login', {
        state: {
          from: location
        },
        replace: true
      });
    } else {
      setOpen(true);
    }
  }

  return (
    <>
    {open && <LectureContent open={open} setOpen={setOpen} lectureId={lectureId} owned={purchased} setOwned={setPurchased} />}
    <div className={styles.lectureCard} onClick={() => handleClick(price, purchased)}>
      <div className={styles.mainSide}>
        <span className={`${styles.lectureIcon} ${open ? styles.dark : ''}`}>
          <MdOutlineSlowMotionVideo />
        </span>
        <section className={styles.text}>
          <h3 className={styles.lectureTitle}>{name}</h3>
          {purchased ? (
            <p className={styles.lecturePurchase}>Already Purchased</p>
          ) : (
            <p className={styles.lecturePurchase}>{price} EGP</p>
          )}
        </section>
      </div>
      <IoIosArrowForward />
    </div>
    </>
  )
}

export default Lecture